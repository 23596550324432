interface i_resourceOptions {
  id: string;
  src: string;
  reload?: boolean;
}

interface i_scriptResourceOptions extends i_resourceOptions {
  type?: "module";
  async?: boolean;
}

async function loadResource(
  id: string,
  resource: HTMLScriptElement | HTMLLinkElement,
  reload?: boolean
) {
  return new Promise((res, rej) => {
    const alreadyExists = document.getElementById(id);

    function handleLoad() {
      res({});
    }

    function handleError(e: any) {
      rej(e);
    }

    if (alreadyExists) {
      if (reload) {
        document.body.removeChild(alreadyExists);
      } else {
        return handleLoad();
      }
    }

    resource.id = id;
    resource.onload = handleLoad;
    resource.onerror = handleError;

    document.body.appendChild(resource);
  });
}

export function loadScript(
  options: i_scriptResourceOptions,
  onLoad: () => void,
  onError: (e: any) => void
) {
  const resource = document.createElement("script");
  resource.src = options.src;
  if (options.type) resource.type = options.type;
  if (options.async !== undefined) resource.async = options.async;
  loadResource(`${options.id}_script`, resource, options.reload)
    .then(onLoad)
    .catch(onError);
}

export function loadStyle(
  options: i_resourceOptions,
  onLoad: () => void,
  onError: (e: any) => void
) {
  const resource = document.createElement("link");
  resource.href = options.src;
  resource.rel = "stylesheet";
  resource.media = "screen";
  loadResource(`${options.id}_style`, resource, options.reload)
    .then(onLoad)
    .catch(onError);
}
