import React, { ReactElement } from "react";
import { navPrefix, transPrefix } from "../../../../utils/helpers";
import { useFormat } from "../../../../hooks/useFormat";
import * as styles from "./index.module.scss";
import { Translate } from "../../../../components/translate";
import { Container } from "../../../../components/container";
import { H_2, H_4 } from "../../../../components/typography/heading";
import {
  e_textTag,
} from "../../../../components/typography/text";

const items = [2, 2, 2, 4, 3, 5];

const compPrefix = transPrefix("installation");

export const Index_Installation: React.FC = () => {
  const { formatString } = useFormat(compPrefix);

  return (
    <section className={styles.bg} id={formatString("link3Anchor", navPrefix)}>
      <Container pt pb mb>
        <div className={styles.layout}>
          {items.map((specItemList, index) => (
            <SpecItem
              key={index}
              subTitle={<Translate id={compPrefix(`item${index}SubTitle`)} />}
              title={<Translate id={compPrefix(`item${index}Title`)} />}
            >
              {Array.from(new Array(specItemList)).map((item, itemIndex) => (
                <div className={styles.listItem} key={itemIndex}>
                  <Translate id={compPrefix(`item${index}Point${itemIndex}`)} />
                  <Translate id={compPrefix(`item${index}Value${itemIndex}`)} />
                </div>
              ))}
            </SpecItem>
          ))}
        </div>
      </Container>
    </section>
  );
};

interface i_benefitProps {
  title: ReactElement;
  subTitle: ReactElement;
}

const SpecItem: React.FC<i_benefitProps> = ({ title, subTitle, children }) => {
  return (
    <div className={styles.specItem}>
      <H_4 tag={e_textTag.p} className={styles.title}>{title}</H_4>
      <H_2 tag={e_textTag.h3} className={styles.subTitle}>{subTitle}</H_2>
      {children}
    </div>
  );
};
