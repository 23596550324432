import React from "react";
import * as styles from "./index.module.scss";
import {
  e_fontWeight,
  e_textAlign,
  Text,
} from "../../../../../../components/typography/text";
import { cls } from "../../../../../../utils/helpers";

interface i_subHeadingProps {
  className?: string;
}

export const Form_SubHeading: React.FC<i_subHeadingProps> = ({
  children,
  className,
}) => {
  return (
    <Text
      className={cls(styles.subHeading, className)}
      fontWeight={e_fontWeight.w500}
      textAlign={e_textAlign.CENTER}
    >
      {children}
    </Text>
  );
};
