import React, { useContext, useEffect, useRef, useState } from "react";
import { e_sellerAction, i_contactFormPage, i_seller } from "../../types";
import { PageWrapper } from "../../components/page-wrapper";
import { cls, transPrefix } from "../../../../../../utils/helpers";
import * as styles from "./index.module.scss";
import { Seller } from "../../components/seller";
import { useFormat } from "../../../../../../hooks/useFormat";
import { e_contactFormPages, ModalStateContext } from "../../index";
import { Translate } from "../../../../../../components/translate";
import { ContactForm_Button } from "../../components/button";
import IconLongArrow from "../../../../../../assets/icons/arrow-long.svg";

const pagePrefix = transPrefix("contact-form_pageSeller");
const rootPrefix = transPrefix("contact-form");

enum e_localErrors {
  getLocation = "error_couldNotGetSellersLocation",
  markOnMap = "error_couldNotMarkOnMap",
}

export const ContactForm_SellerPage: React.FC<i_contactFormPage> = ({
  page,
  data,
}) => {
  const [formData, setFormData] = data;
  const { formatString } = useFormat(pagePrefix);
  const { api, error } = useContext(ModalStateContext);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const [localError, setLocalError] = useState("");

  async function getSellersLocation(seller: any) {
    try {
      const mapWrapper = mapRef.current;
      if (mapWrapper && api) {
        const sellersPlace = await api.getLocationFromPlaceId(seller.placeId);
        return sellersPlace.geometry.location;
      }
    } catch (e) {
      console.error(e);
      setLocalError(formatString(e_localErrors.getLocation, rootPrefix));
    }
  }

  async function markOnMap(seller: i_seller) {
    try {
      const mapWrapper = mapRef.current;
      if (mapWrapper && api) {
        const coords = await getSellersLocation(seller);
        if (coords) {
          api.setupMap(mapWrapper, coords);
          api.addMarkerToMap(coords);
        }
      }
    } catch (e) {
      console.error(e);
      setLocalError(formatString(e_localErrors.markOnMap, rootPrefix));
    }
  }

  async function changeSeller(seller: i_seller) {
    if (api && formData.pickedSeller !== seller) {
      const coords = await getSellersLocation(seller);
      if (coords) {
        api.updateMap(coords);
        api.updateMarker(coords);
      }
      setFormData({
        ...formData,
        pickedSeller: seller,
      });
    }
  }

  function handleAction(
    actionNumber: e_sellerAction,
    seller: i_seller,
    url?: string
  ) {
    if (actionNumber === e_sellerAction.buy) {
      // call store api
      page.close();
    }

    if (actionNumber === e_sellerAction.store) {
      // redirect store
      if (seller.link) {
        window.location.href = seller.link;
      }
    }

    if (actionNumber === e_sellerAction.contact) {
      setFormData({ ...formData, contactSeller: seller });
      page.next(e_contactFormPages.CONTACT_SELLER);
    }
  }

  useEffect(() => {
    const handleLoad = async () => {
      const sellers = formData.sellersInCountry;
      if (sellers) {
        await markOnMap(sellers[0]);
        setFormData({
          ...formData,
          pickedSeller: sellers[0],
        });
      }
    };
    handleLoad();
  }, [mapRef]);

  return (
    <PageWrapper
      closeHandler={page.close}
      heading={formatString("heading")}
      backButtonHandler={page.back}
      className={styles.modalContent}
      contentClassName={styles.page}
      error={localError ? localError : error}
    >
      <div
        className={cls(styles.content, error || localError ? styles.error : "")}
      >
        {!error && localError !== "excluded error name" ? (
          <>
            <div className={styles.left}>
              <div className={styles.listWrapper}>
                {formData.sellersInCountry.map((seller, index) => (
                  <Seller
                    onClick={() => changeSeller(seller)}
                    data={seller}
                    onAction={handleAction}
                    active={true}
                    detailed
                    key={index}
                    className={cls(
                      styles.pickedSeller,
                      styles.seller,
                      seller === formData.pickedSeller && styles.selected
                    )}
                  />
                ))}
              </div>
              <ContactForm_Button
                onClick={() => page.next(e_contactFormPages.ALL_SELLERS)}
                biggerFont={true}
                textVariant={true}
                className={styles.showAll}
              >
                <Translate id={rootPrefix("showAll")} />
                <IconLongArrow className={styles.arrowIcon} />
              </ContactForm_Button>
            </div>
            <div className={styles.map} ref={mapRef} />
          </>
        ) : (
          <ContactForm_Button
            onClick={() => page.next(e_contactFormPages.ALL_SELLERS)}
            biggerFont
          >
            <Translate id={rootPrefix("showAll")} />
          </ContactForm_Button>
        )}
      </div>
    </PageWrapper>
  );
};
