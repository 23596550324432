import { isBrowser } from "./helpers";

export interface i_imageDataQueryResult {
  childImageSharp: {
    fluid: any;
  };
}

export function filterImageFromAllFileQueryByName(query: any, name: string) {
  const selectedImg = query.allFile.edges.find(
    (edge: any) => edge.node.relativePath === name
  );

  if (!selectedImg) {
    throw new Error(`Image with name ${name} not found.`);
  }

  return selectedImg;
}

export function getResponsiveImage(node: i_imageDataQueryResult): string {
  const resultSet = node.childImageSharp.fluid;
  let res = resultSet.src.split(" ")[0];

  if (isBrowser() && resultSet.srcSet) {
    const srcArray = resultSet.srcSet.replace(/(\r\n|\n|\r)/gm, "").split(",");

    for (let i = 0; i < srcArray.length; i++) {
      const [src, width] = srcArray[i].split(" ");
      if (Number(width.replace("w", "")) >= window.screen.width) {
        res = src;
        break;
      }
    }
  }

  return res;
}
