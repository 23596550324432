//@refactored
import React, { ReactElement } from "react";
import * as styles from "./index.module.scss";
import { H_2, H_4 } from "../../../../components/typography/heading";
import {
  e_textAlign,
  e_textTag,
  TextMedium,
} from "../../../../components/typography/text";
import { Container } from "../../../../components/container";
import { makeCircle } from "../../../../utils/react";
import { Translate } from "../../../../components/translate";
import { useFormat } from "../../../../hooks/useFormat";
import { cls, navPrefix, transPrefix } from "../../../../utils/helpers";
import { Image } from "../../../../components/image";
import IconLongArrow from "../../../../assets/icons/arrow-long.svg";

const compPrefix = transPrefix("benefits");

export const Index_Benefits: React.FC = () => {
  const { formatString } = useFormat(compPrefix);
  return (
    <>
      <section
        className={styles.section}
        id={formatString("link1Anchor", navPrefix)}
      >
        <Container className={styles.container}>
          <div className={styles.content}>
            <H_4
              tag={e_textTag.p}
              textAlign={e_textAlign.CENTER}
              className={styles.h4}
            >
              <Translate id={compPrefix("h4")} />
            </H_4>
            <H_2
              tag={e_textTag.h2}
              textAlign={e_textAlign.CENTER}
              className={styles.h1}
            >
              <Translate id={compPrefix("h1")} />
            </H_2>
            <TextMedium className={cls(styles.text, styles.text1)}>
              <Translate id={compPrefix("mainText1")} />
            </TextMedium>
            <TextMedium className={styles.text}>
              <Translate id={compPrefix("mainText2")} />
            </TextMedium>
			<TextMedium className={styles.text}>
              <Translate id={compPrefix("mainText3")} />
            </TextMedium>
          </div>
        </Container>
        <Image
          alt={formatString("imgAlt")}
          name={formatString("imgName")}
          className={styles.img}
        />
      </section>

      <section>
        <div className={styles.bg}>
          {makeCircle(500, 200, styles.circleOutlined)}
          {makeCircle(45, 0, styles.circleFilled)}
          {makeCircle(20, 0, styles.circleFilled)}
          <Container pt2>
            <div className={styles.layout}>
              {Array.from(new Array(6)).map((benefit, index) => (
                <Benefit
                  key={index}
                  title={<Translate id={compPrefix(`item${index}Title`)} />}
                >
                  <Translate id={compPrefix(`item${index}Content`)} />
                  {index === 3 && (
                    <span className={styles.previewBtnWrapper}>
                      <a
                        className={styles.previewBtn}
                        href={`#${formatString("link2Anchor", navPrefix)}`}
                      >
                        <Translate id={compPrefix("preview")} />
                        <IconLongArrow />
                      </a>
                    </span>
                  )}
                </Benefit>
              ))}
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

interface i_benefitProps {
  title: ReactElement;
}

const Benefit: React.FC<i_benefitProps> = ({ title, children }) => {
  return (
    <div className={styles.benefit}>
      <H_2 tag={e_textTag.h3} className={styles.title}>
        {title}
      </H_2>
      <TextMedium className={styles.benefitText}>{children}</TextMedium>
    </div>
  );
};
