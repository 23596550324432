import {COUNTRY_CODES, e_sellerType, i_seller} from "./types";

export const DATA_SELLER_INDEXES = {
    b2b: 0,
    abzo: 1,
};

export const DATA_SELLERS: i_seller[] = [
    //ALBIXON
    {
        internal: true,
        id: "ALBIXON",
        name: "Albixon",
        type: e_sellerType.store,
        country: COUNTRY_CODES.CZ,
        link: "https://www.albixon.cz/",
        contact: {
            address: "Zbraslavská 55/5a || Praha 5 || 159 00",
            email: "podpora@albixon.cz",
        },
        coord: {
            lat: 50.024642,
            lng: 14.392234,
        },
        placeId: "ChIJfwtbVJiWC0cRlzB_fzig75U",
    },
    //ABZO
    {
        internal: true,
        id: "ABZO",
        name: "Albixon",
        type: e_sellerType.contact,
        country: COUNTRY_CODES.CZ,
        link: "https://www.albixon.com/",
        contact: {
            address: "Zbraslavská 55/5a || Praha 5 || 159 00",
            email: "export@albixon.com",
        },
        coord: {
            lat: 50.024642,
            lng: 14.392234,
        },
        placeId: "ChIJfwtbVJiWC0cRlzB_fzig75U",
    },
    {
        id: "30",
        name: "Albixon.cz",
        type: e_sellerType.contact,
        country: COUNTRY_CODES.CZ,
        link: "https://www.albixon.cz",
        contact: {
            address: "Zbraslavská 55/5a || Praha 5 || 159 00",
            email: "podpora@albixon.cz",
        },
        coord: {
            lat: 50.024642,
            lng: 14.392234,
        },
        placeId: "ChIJfwtbVJiWC0cRlzB_fzig75U",
    },
    {
        id: "0",
        name: "Pool - Systems",
        link: "https://www.pool-systems.de/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "GhIJQWDl0CK2SEARQ4ts5_v1KEA",
        coord: {lat: 49.4229459, lng: 12.4804359},
        contact: {
            email: "info@pool-systems.de",
            address: "Am Steinbruch 3-5 || 92559 Winklarn || Germany",
            phone: "+475 455 455 445",
        },
    },
    {
        id: "1",
        name: "Pool - Systems",
        link: "https://www.pool-systems.de/",
        country: COUNTRY_CODES.AT,
        type: e_sellerType.contact,
        placeId: "GhIJQWDl0CK2SEARQ4ts5_v1KEA",
        coord: {lat: 49.4229459, lng: 12.4804359},
        contact: {
            email: "info@pool-systems.de",
            address: "Am Steinbruch 3-5 || 92559 Winklarn || Germany",
        },
    },
    {
        id: "2",
        name: "Pool - Systems",
        link: "https://www.pool-systems.de/",
        country: COUNTRY_CODES.CH,
        type: e_sellerType.contact,
        placeId: "GhIJQWDl0CK2SEARQ4ts5_v1KEA",
        coord: {lat: 49.4229459, lng: 12.4804359},
        contact: {
            email: "info@pool-systems.de",
            address: "Am Steinbruch 3-5 || 92559 Winklarn || Germany",
        },
    },
    {
        id: "3",
        name: "LLC BASSEINY I SPA",
        link: "https://www.pool-covers.ru/",
        country: COUNTRY_CODES.RU,
        type: e_sellerType.contact,
        placeId: "GhIJdr6fGi_gS0ARl24Sg8DVQkA",
        coord: {lat: 55.751423, lng: 37.6696568},
        contact: {
            email: "sale@pool-spa.ru",
            address: "Nizhnyaya Syromyatnicheskaya st., 10 - 3. Entrance A || Moscow",
        },
    },
    {
        id: "4",
        name: 'Baseinai-Op.lt, UAB "Tavira"',
        link: "https://www.baseinai-op.lt/",
        country: COUNTRY_CODES.LT,
        type: e_sellerType.contact,
        placeId: "GhIJBoGVQ4txS0ARppvEILDcN0A",
        coord: {lat: 54.886137, lng: 23.861819},
        contact: {
            email: "eimantas@tavira.lt",
            address: "Šiltnamių g. 58 || Noreikiškių k. 53371 || Lithuania",
        },
    },
    {
        id: "5",
        name: "Fibrex Co S.R.L",
        link: "https://www.fibrexco.ro/",
        country: COUNTRY_CODES.RO,
        type: e_sellerType.contact,
        placeId: "ChIJQQkY7wZlSEcR0g-hBnIyUuo",
        coord: {lat: 47.1457665, lng: 22.8719007},
        contact: {
            email: "pop.cristian@fibrexco.ro",
            address: "Strada Ciucei 752 || Crasna 457085 || Romania",
        },
    },
    {
        id: "6",
        name: "OGNES-NORD SRL",
        link: "https://www.ognes.md/",
        country: COUNTRY_CODES.MD,
        type: e_sellerType.contact,
        placeId: "ChIJNdMPKkV8yUARRQL_M_-9ceg",
        coord: {lat: 47.0342397, lng: 28.8566246},
        contact: {
            email: "ognes@rambler.ru",
            address: "Strada Andrei Doga 30 || Chișinău || Moldova",
        },
    },
    {
        id: "7",
        name: "SC TOTAL WATER CONCEPT SRL",
        link: "https://www.totalwater.ro/",
        country: COUNTRY_CODES.RO,
        type: e_sellerType.contact,
        placeId: "ChIJpQKg9af_sUARxxHUNNdzDX4",
        coord: {lat: 44.409449, lng: 26.1484087},
        contact: {
            email: "vali@totalwater.ro",
            address:
                "Strada Locotenent Nicolae || Pascu nr.61-63 || București 032073 || Romania",
        },
    },
    {
        id: "8",
        name: "Bibis d.o.o.",
        link: "https://www.bibis.rs/",
        country: COUNTRY_CODES.SRB,
        type: e_sellerType.contact,
        placeId: "ChIJd8sw429lWkcR9Qp6HmcY61E",
        coord: {lat: 44.8107238, lng: 20.4550706},
        contact: {
            email: "dragana@bibis.rs",
            address: "Gavrila Principa 57 || Beograd || Serbia",
        },
    },
    {
        id: "9",
        name: "Isla Medina S.A",
        link: "https://www.bigmat.es/",
        country: COUNTRY_CODES.ES,
        type: e_sellerType.contact,
        placeId: "GhIJWmQ7309GREARp5vEILDiDMA",
        coord: {lat: 40.5493614, lng: -3.611128},
        contact: {
            email: "marusi.islamedina@bigmat.es",
            address:
                "Av. de los Pirineos, 7, 1ª || 28700 San Sebastián de los Reyes || Madrid || Spain",
        },
    },
    {
        id: "10",
        name: "POOL MASTER GROUP SRL",
        link: "https://www.latuapiscina.ro/",
        country: COUNTRY_CODES.RO,
        type: e_sellerType.contact,
        placeId: "ChIJFyeWSXcMSUcRBwHNWxpgqNs",
        coord: {lat: 46.7818331, lng: 23.6470653},
        contact: {
            email: "liviu@latuapiscina.ro",
            address: "Strada Băii 15 || Cluj-Napoca 400389 || Romania",
        },
    },
    {
        id: "11",
        name: "IFK-Ampro Kft.",
        link: "https://www.ampron.hu/",
        country: COUNTRY_CODES.HU,
        type: e_sellerType.contact,
        placeId: "ChIJoVNzt7tGakcRmzNE6CPwR-c",
        coord: {lat: 47.5064548, lng: 19.0813491},
        contact: {
            email: "sales@ampron.hu",
            address: "Vértesszőlős || Valusek Dezső u. 62 || 2837 || Hungary",
        },
    },
    {
        id: "13",
        name: "SCHILLACI COPERTURE SRL",
        link: "https://www.schillacicoperture.it/",
        country: COUNTRY_CODES.IT,
        type: e_sellerType.contact,
        placeId: "ChIJhXjj57O5KxMRaNnE97o-iGs",
        coord: {lat: 43.5521714, lng: 11.5540429},
        contact: {
            email: "amministrazione@schillacicoperture.it",
            address:
                "8° di, Via V strada Poggilupi || 91 || 52028 Terranuova Bracciolini AR || Itally",
        },
    },
    {
        id: "14",
        name: "OÜ Basseiniekspert",
        link: "http://www.basseiniekspert.ee/",
        country: COUNTRY_CODES.EE,
        type: e_sellerType.contact,
        placeId: "ChIJ68SVjo7skkYRBVq1vrvE9KY",
        coord: {lat: 59.4277185, lng: 24.8699707},
        contact: {
            email: "anatoli@basseiniekspert.ee",
            address: "11415 Tallinn || Estonia",
        },
    },
    {
        id: "15",
        name: "Aqualing Kft.",
        link: "https://www.aqualing.hu/",
        country: COUNTRY_CODES.HU,
        type: e_sellerType.contact,
        placeId: "GhIJTmIQWDm5R0AR30-Nl24MM0A",
        coord: {lng: 47.465826, lat: 19.0293439},
        contact: {
            email: "attila@aqualing.hu",
            address: "Budapest || Hunyadi János út 4 || 1117 || Hungary",
        },
    },
    {
        id: "16",
        name: "Baroal Gestión y Calidad S.L.",
        link: "http://www.baroal.es/",
        country: COUNTRY_CODES.ES,
        type: e_sellerType.contact,
        placeId: "GhIJ001iEFg0REARq_HSTWJADcA",
        coord: {lat: 40.3833336, lng: -3.6964024},
        contact: {
            email: "info@baroal.es",
            address: "Calle Entre Arroyos || 1, 3A 28030 || Madrid || Spain",
        },
    },
    {
        id: "17",
        name: "KPB SLOVAKIA s.r.o.",
        link: "https://www.pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJE0C5V7yhFEcRFw0WnYS8I9k",
        coord: {lat: 48.8982466, lng: 18.0142147},
        contact: {
            email: "lubos.horak@pooltime.sk",
            address: "Vážska 265/1 || 911 05 Trenčín-Nové Zlatovce || Slovakia",
        },
    },
    {
        id: "18",
        name: "Unicentre s.r.o.",
        link: "http://www.unicentre.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "GhIJDAIrhxYYSEAR_Knx0k24MUA",
        coord: {lat: 48.1866961, lng: 17.717674},
        contact: {
            email: "unicentre@unicentre.sk",
            address: "Bratislavská 82/16 || 924 01 Galanta || Slovakia",
        },
    },
    {
        id: "20",
        name: "RIVIL s.r.o",
        link: "https://www.pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJexXuw77gPkcRm-WZiLOmoUA",
        coord: {lat: 48.7522094, lng: 21.2733919},
        contact: {
            email: "kosice@pooltime.sk",
            address:
                "Ázijská trieda 3 || 040 13 Sídlisko Ťahanovce || Košice || Slovakia",
        },
    },
    {
        id: "22",
        name: "POOLTIME (SK)",
        link: "http://www.pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJRUPu7I2PbEcR4pj208R_88Q",
        coord: {lat: 48.1604272, lng: 17.1666386},
        contact: {
            email: "bratislava@pooltime.sk",
            address: "Galvaniho 2/A || 821 04 Bratislava || Slovakia",
        },
    },
    {
        id: "23",
        name: "BAZENOVY SPECIALISTA s.r.o.",
        link: "https://www.bazenovyspecialista.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJG7ba1hA_a0cR52I3cCHuCjg",
        coord: {lat: 48.3127796, lng: 18.0760712},
        contact: {
            email: "peter.konderik@bazenovyspecialista.sk",
            address: "Vodná 1128/23 || 949 01 Nitra || Slovakia",
        },
    },
    {
        id: "24",
        name: "Ambassador Pools - Wellis Hampshire",
        link: "https://www.ambassadorpools.co.uk/",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "ChIJ9x3g4UqXc0gRLq1srxgxSiQ",
        coord: {lat: 50.8498758, lng: -1.7779271},
        contact: {
            email: "sales@ambassadorpools.co.uk",
            address: "3-5 || Wessex Rd || Ringwood BH24 1XB || United Kingdom",
        },
    },
    {
        id: "31",
        name: "Ambassador Pools & Leisure - Southampton",
        link: "https://www.ambassadorpools.co.uk/",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "ChIJHa8bwLFvdEgR2Ar2loFvJB4",
        coord: {lat: 50.87388179404944, lng: -1.2676958550358959},
        contact: {
            address:
                "74 Botley Road || Park Gate || Southampton || Hampshire || SO31 1BA || United Kingdom",
            email: "mike@ambassadorpools.co.uk",
        },
    },
    {
        id: "29",
        name: "1st Direct Pools",
        link: "https://www.1stdirectpools.com/",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "GhIJBoGVQ4stSkARbhKDwMrh5b8",
        coord: {lat: 50.99465303816349, lng: -2.341282143625122},
        contact: {
            address:
                "Ambassador Business Park || Gillingham || Dorset || SP8 5SE || United Kingdom",
            email: "sales@1stdirectpools.com",
        },
    },
    {
        id: "28",
        name: "Panache Pools Limited",
        link: "https://www.panachepools.co.uk",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "GhIJ_Knx0k1_SUARiBbZzvcT5T8",
        coord: {lat: 52.35583587383513, lng: -0.6837854106246745},
        contact: {
            address:
                "Bosworth’s Garden Centre, 110 Finedon Road || Burton Latimer || Kettering || Northamptonshire || NN15 5QA ",
            email: "enquiries@panachepools.co.uk",
        },
    },
    {
        id: "33",
        name: "My Pool Direct",
        link: "https://www.mypooldirect.co.uk",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "EjQ1IERvd25oYW0gUmQsIFJhbXNkZW4gSGVhdGgsIEJpbGxlcmljYXkgQ00xMSAxSEosIFVLIjASLgoUChIJ8-qb89XD2EcRna45D9cQ-7cQBSoUChIJdUD37DDC2EcR7zL753Jmdcc",
        coord: {lat: 51.63631218818122, lng: 0.47400666882947373},
        contact: {
            address:
                "5-7 Downham Road || Ramsden Heath || Billericay || Essex || CM11 1PU || United Kingdom",
            email: "sales@mypooldirect.co.uk",
        },
    },
    {
        id: "32",
        name: "AQUINIUM",
        link: "https://aquinium.co.uk/",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "ChIJ84zHUuWve0gR3IwXkryjzbo",
        coord: {lat: 53.53098937243188, lng: -2.2813448719809344},
        contact: {
            address:
                "10 Oakhurst Gardens || Prestwich || Manchester || M25 1JQ || United Kingdom",
            email: "sales@aquinium.co.uk",
        },
    },
    {
        id: "34",
        name: "Arch Enclosures",
        link: "https://www.archenclosures.co.uk",
        country: COUNTRY_CODES.UK,
        type: e_sellerType.contact,
        placeId: "EipNaWxsIEdyZWVuIFJkLCBIYXl3YXJkcyBIZWF0aCBSSDE2IDFBQSwgVUsiLiosChQKEgmJxvz4rYx1SBEESL7itzksOBIUChIJaf-j4rGMdUgRh2mKOwCklBU",
        coord: {lat: 51.00753699746745, lng: -0.10382822995388019},
        contact: {
            address:
                "Mill Green Rd || Haywards || Heath || RH16 1AA || United Kingdom",
            email: "enquiries@archenclosures.co.uk",
        },
    },
    {
        id: "35",
        name: "UPOOLIA GMBH",
        link: "https://www.upoolia.com/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJE14UKW_Ol0cRBBr4Ro9yqqc",
        coord: {lat: 49.51396894611633, lng: 8.504069642670283},
        contact: {
            email: "info@upoolia.com",
            address: "Reichenbachstraße 27-31 || 68309 Mannheim Am Steinbruch || Germany",
            phone: "+49 621 978 77 99",
        },
    },
    {
        id: "36",
        name: "POOLUNION",
        link: "https://www.poolunion.de/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJVVXpwn3Ml0cRI_GsTjRl9pA",
        coord: {lat: 49.47205557943326, lng: 8.421950652299998},
        contact: {
            email: "info@poolunion.de",
            address: "TZL - Technologiezentrum Ludwigshafen || Donnersbergweg 1 || 67059 Ludwigshafen am Rhein || Germany",
            phone: "+49 621 877 533 99",
        },
    },
    {
        id: "37",
        name: "KROISS CHIEMSEEPOOL GB",
        link: "https://www.chiemseepool.de/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJdWt9QGhpdkcRwbRsUzB2THg",
        coord: {lat: 47.777998299099316, lng: 12.46732731170464},
        contact: {
            email: "mail@chiemseepool.de",
            address: "Bahnhofstraße 100 || 83224 Grassau || Germany",
            phone: "08641/1206",
        },
    },
    {
        id: "38",
        name: "FKB SYSTEMTECHNIK GMBH",
        link: "https://www.schwimmbadtechnik-fkb.de/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJ34z-p6DWCUcRPxhxRY-0AQk",
        coord: {lat: 51.1485964974942, lng: 13.556280896607397},
        contact: {
            email: "info@schwimmbadtechnik-fkb.de",
            address: "Sörnewitzer Straße 43 || 01689 Weinböhla || Germany",
            phone: "0049 035243 499000",
        },
    },
    {
        id: "39",
        name: "POOLSPECIAL",
        link: "https://www.poolspecial.de/",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJa_i5Y6bTuEcRkSMbKooJt6w",
        coord: {lat: 51.18299790137441, lng: 7.021393910100651},
        contact: {
            email: "verkauf@poolspecial.de",
            address: "Elbingerstraße 10 || 42719 Solingen || Germany",
            phone: "+49 0212 596 797 54",
        },
    },
    {
        id: "40",
        name: "Firstpools GmbH",
        link: "",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJjemxnWZvmUcRF3u3oenu7zA",
        coord: {lat: 48.45463519703529, lng: 9.968259140574432},
        contact: {
            email: "info@firstpools.de",
            address: "Im Leherer Feld 44 || 89081 Ulm || Germany",
            phone: "+49-731-6023966",
        },
    },
    {
        id: "41",
        name: "MINERVA Pool GmbH & Co. KG",
        link: "",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJydJtGsGul0cRiyni7zJVwzM",
        coord: {lat: 49.15795958134923, lng: 8.407552218228622},
        contact: {
            email: "info@minerva-poolbau.de",
            address: "Am Hambiegel 22 || 76756 Dettenheim || Germany",
            phone: "+49 157 52 78 96 52",
        },
    },
    {
        id: "42",
        name: "POOL EXPRESS",
        link: "https://pool-express.de",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJo2FZmg0AqUcR458kKkEocMQ",
        coord: {lat: 52.670085958722574, lng: 13.217669540716773},
        contact: {
            email: "info@pool-express.de",
            address: "Havelring 10 || 16727 Velten || Germany",
            phone: "03304 243 64 26",
        },
    },
    {
        id: "43",
        name: "S&K Poolbau GmbH",
        link: "https://www.poolbau-bonn.de",
        country: COUNTRY_CODES.DE,
        type: e_sellerType.contact,
        placeId: "ChIJfZvw1XTkvkcRIOTXqU6rEVk",
        coord: {lat: 50.689038221251764, lng: 7.14721047133412},
        contact: {
            email: "info@poolbau-bonn.de",
            address: "Friesdorfer Str. 122 || 53173 Bonn || Germany",
            phone: "0228 76 38 97 61",
        },
    },
    {
        id: "44",
        name: "Pooldoktor Handels GmbH",
        link: "https://pooldoktor.at/",
        country: COUNTRY_CODES.AT,
        type: e_sellerType.contact,
        placeId: "EiVLb3R6aW5hc3RyYcOfZSAxNSwgNDAzMCBMaW56LCBBdXN0cmlhIjASLgoUChIJu_tG2hGWc0cRmbydwJOettEQDyoUChIJ18fw3RGWc0cRjZTeKmCX0uI",
        coord: {lat: 48.24063955300743, lng: 14.28428396755344},
        contact: {
            email: "office@pooldoktor.at",
            address: "Kotzinastrasse 15 || A - 4030 Linz || Austria",
            phone: "+43 732 382222",
        },
    },
    {
        id: "45",
        name: "ANTON WANDL",
        link: "https://www.wepomo.at/",
        country: COUNTRY_CODES.AT,
        type: e_sellerType.contact,
        placeId: "ChIJeYFxIXLcckcRzw8MtDrPuFo",
        coord: {lat: 48.75866010895618, lng: 15.175849755927178},
        contact: {
            email: "vitis@wepomo.at",
            address: "Rudolf Süss Straβe 8 || 3902 Vitis || Austria",
            phone: "+43 2841 805 41",
        },
    },
    {
        id: "46",
        name: "POOLTIME BAZÉNOVÉ CENTRUM BRATISLAVA",
        link: "https://pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJyfa2LeKObEcRh1ZuZj3m0jE",
        coord: {lat: 48.17838111264602, lng: 17.169377040565433},
        contact: {
            email: "bratislava@pooltime.sk",
            address: "Galvaniho 2/A || 821 04 Bratislava || Slovakia",
            phone: "+421 901 91 92 93",
        },
    },
    {
        id: "47",
        name: "POOLTIME BAZÉNOVÉ CENTRUM TRENČÍN",
        link: "https://pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJdaeqV7yhFEcRrpUu3m0sY-8",
        coord: {lat: 48.898761314080964, lng: 18.015056083814414},
        contact: {
            email: "trencin@pooltime.sk",
            address: "Vážska 1 || 911 05 Trenčín || Slovakia",
            phone: "+421 911 800 199",
        },
    },
    {
        id: "48",
        name: "POOLTIME BAZÉNOVÉ CENTRUM KOŠICE",
        link: "https://pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "Ei_DgXppanNrw6EgdHJpZWRhIDM4MTMsIDA0MCAxMyBLb8WhaWNlLCBTbG92YWtpYSIxEi8KFAoSCQUBmgK_4D5HESdVoeWEUEqqEOUdKhQKEgnFezKIuOA-RxEEvBdba61DJw",
        coord: {lat: 48.75163409957586, lng: 21.2718085261377},
        contact: {
            email: "kosice@pooltime.sk",
            address: "Ázijská trieda 3813/3 || 040 13 Košice || Slovakia",
            phone: "+421 949 642 246",
        },
    },
    {
        id: "49",
        name: "POOLTIME BAZÉNOVÉ CENTRUM TRNAVA",
        link: "https://pooltime.sk/",
        country: COUNTRY_CODES.SK,
        type: e_sellerType.contact,
        placeId: "ChIJpVnxmHygbEcRICVvdn5INug",
        coord: {lat: 48.37173121252993, lng: 17.57573517008304},
        contact: {
            email: "trnava@pooltime.sk",
            address: "Jána Bottu 36 || 917 01 Trnava || Slovakia",
            phone: "+421 907 367 969",
        },
    },
    {
        id: "50",
        name: "POOLDISCOUNT",
        link: "https://www.pooldiscount.ch/",
        country: COUNTRY_CODES.CH,
        type: e_sellerType.contact,
        placeId: "EidCYWFyZXJzdHJhc3NlIDI1LCA2MzAwIFp1ZywgU3dpdHplcmxhbmQiMBIuChQKEgnXOR1CWKqaRxF891hXPh9WShAZKhQKEgmj52whWqqaRxEOrheqNCSeaA",
        coord: {lat: 47.172896537944894, lng: 8.516434421540538},
        contact: {
            email: "info@pooldiscount.ch",
            address: "Baarerstrasse 25 || 6300 Zug || Switzerland",
            phone: "+041 560 01 50",
        },
    },
    {
        id: "51",
        name: "ALLBAUTECH UKRAINE",
        link: "https://www.albion.ua/",
        country: COUNTRY_CODES.UA,
        type: e_sellerType.contact,
        placeId: "ChIJWxt4AlHO1EARWN4z7ByGvvA",
        coord: {lat: 50.450106867729346, lng: 30.523421541737225},
        contact: {
            email: "albion@albion.ua",
            address: "Prospect Goloseevsky, 27, of. 7|| 03 680 Kyiv || Ukraine",
            phone: "+380 672 318 897",
        },
    },
];
