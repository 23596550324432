import React, { useContext, useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { ContactFormInput } from "../../components/input";
import { ContactForm_Button } from "../../components/button";
import { Translate } from "../../../../../../components/translate";
import {
  e_fontWeight,
  Text,
} from "../../../../../../components/typography/text";
import { PageWrapper } from "../../components/page-wrapper";
import { i_contactFormPage } from "../../types";
import { e_contactFormPages, ModalStateContext } from "../../index";
import { useFormat } from "../../../../../../hooks/useFormat";
import { cls, transPrefix } from "../../../../../../utils/helpers";
import { Form_SubHeading } from "../../components/subheading";
import { PageStateContext } from "../../../../../../components/page-state-provider";
import { t_geoCodedLocation } from "../../maps";
import { ContactForm_Loader } from "../../components/loader";
import LocationIcon from "../../../../../../assets/icons/location.svg";

const pagePrefix = transPrefix("contact-form_pageAddress");
const rootPrefix = transPrefix("contact-form");

function getGeoLocation(): Promise<{ lat: number; lng: number }> {
  return new Promise((res, rej) => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        res({ lat, lng });
      },
      (e) => {
        rej(e);
      }
    );
  });
}

export const ContactForm_AddressPage: React.FC<i_contactFormPage> = ({
  page,
  data,
}) => {
  const [formData, setFormData] = data;
  const { formatString } = useFormat(pagePrefix);
  const { api: mapsApi, error } = useContext(ModalStateContext);
  const inputRef = useRef<HTMLInputElement>();
  const { viewportHeight } = useContext(PageStateContext);
  const [selectedPlace, setSelectedPlace] =
    useState<null | t_geoCodedLocation>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [localError, setLocalError] = useState("");
  const [geoLocationLoading, setGeoLocationLoading] = useState(false);

  async function locatePosition() {
    try {
      setGeoLocationLoading(true);
      const { lat, lng } = await getGeoLocation();
      if (mapsApi) {
        const location = await mapsApi.getLocationFromLatLng(lat, lng);
        setSelectedPlace(location);
        if (inputRef.current) {
          inputRef.current.value = location.formatted_address;
        }
      }
      setGeoLocationLoading(false);
    } catch (e) {
      console.error(e);
      setGeoLocationLoading(false);
      setLocalError(formatString("error_geolocation", rootPrefix));
    }
  }

  function continueToNextStep() {
    if (selectedPlace) {
      setFormData({
        ...formData,
        selectedLocation: selectedPlace,
      });
      page.next(e_contactFormPages.LOADER);
    }
  }

  function hideSubHeading() {
    return inputFocused && viewportHeight < 400;
  }

  useEffect(() => {
    function handleEnter(e: KeyboardEvent) {
      if (e.key === "Enter") {
        continueToNextStep();
      }
    }
    window.addEventListener("keypress", handleEnter);
    return function () {
      window.removeEventListener("keypress", handleEnter);
    };
  }, [selectedPlace]);

  useEffect(() => {
    const input = inputRef.current;
    if (input && mapsApi) {
      mapsApi.setupAutoComplete(input, (place) => {
        setSelectedPlace(place);
      });
    }
  }, [inputRef, mapsApi]);

  return (
    <PageWrapper
      closeHandler={page.close}
      contentClassName={styles.wrapperContent}
      heading={formatString("heading")}
      headerClassName={styles.header}
      error={localError ? localError : error}
      subHeading={
        <Form_SubHeading
          className={cls(styles.subheading, hideSubHeading() && styles.small)}
        >
          <Translate id={pagePrefix("subHeading")} />
        </Form_SubHeading>
      }
    >
      <div className={styles.addressInputWrapper}>
        <ContactFormInput
          onBlur={() => setInputFocused(false)}
          onFocus={() => setInputFocused(true)}
          className={styles.addressInput}
          placeholder={formatString("addressPlaceholder")}
          getRef={(ref) => (inputRef.current = ref)}
        />
        {geoLocationLoading ? (
          <ContactForm_Loader small />
        ) : (
          <ContactForm_Button
            onClick={() => locatePosition()}
            icon={<LocationIcon />}
            biggerFont={true}
            textVariant={true}
            className={styles.localize}
          >
            <Translate id={pagePrefix("locationButton")} />
          </ContactForm_Button>
        )}
      </div>
      <div className={styles.choiceWrapper}>
        <ContactForm_Button
          onClick={continueToNextStep}
          biggerFont={true}
          thickerFont={true}
          disabled={!selectedPlace}
        >
          <Translate id={rootPrefix("continue")} />
        </ContactForm_Button>
        <Text className={styles.divider} fontWeight={e_fontWeight.w700}>
          <Translate id={rootPrefix("or")} />
        </Text>
        <ContactForm_Button
          onClick={() => page.next(e_contactFormPages.ALL_SELLERS)}
          textVariant
        >
          <Translate id={rootPrefix("showAll")} />
        </ContactForm_Button>
      </div>
    </PageWrapper>
  );
};
