import React, { ChangeEvent, useState } from "react";
import * as styles from "./index.module.scss";
import { i_defaultComponentProps } from "../../../../../../utils/interfaces";
import { cls } from "../../../../../../utils/helpers";

export enum e_inputType {
  TEXT = "text",
  NUMBER = "number",
  EMAIL = "email",
  TEL = "tel",
}

interface i_inputProps extends i_defaultComponentProps {
  type?: e_inputType;
  placeholder?: string;
  require?: boolean;
  onChange?: (value: string) => void;
  getRef?: (ref: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const ContactFormInput: React.FC<i_inputProps> = ({
  className,
  type = e_inputType.TEXT,
  placeholder,
  onChange,
  onFocus,
  required,
  onBlur,
  getRef = () => {},
}) => {
  const [value, setValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      <input
        className={cls(styles.input, className)}
        type={type}
        placeholder={placeholder}
        required={required}
        value={onChange && value}
        ref={(e) => {
          if (getRef) getRef(e);
        }}
        onFocus={onFocus && onFocus}
        onChange={onChange && handleChange}
        onBlur={onBlur && onBlur}
        autoComplete={"true"}
      />
    </>
  );
};

interface i_textAreaProps extends i_defaultComponentProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  getRef?: (ref: any) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const ContactFormMessage: React.FC<i_textAreaProps> = ({
  placeholder,
  onChange,
  getRef,
  onFocus,
  onBlur,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setValue(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <textarea
      className={styles.textarea}
      placeholder={placeholder}
      ref={(e) => {
        if (getRef) getRef(e);
      }}
      onFocus={onFocus && onFocus}
      value={onChange && value}
      onChange={onChange && handleChange}
      onBlur={onBlur && onBlur}
      cols={30}
      rows={5}
    />
  );
};

interface i_textSelectProps {
  options: {
    label: string;
    value: any;
  }[];
  onChange: (value: string) => void;
  name: string;
  id: string;
  label: string;
  className?: string;
}

export const ContactFormSelect: React.FC<i_textSelectProps> = ({
  options,
  onChange,
  name,
  id,
  label,
  className,
}) => {
  return (
    <div className={cls(styles.countrySelect, className)}>
      <span className={styles.label}>{label}</span>
      <select
        name={name}
        id={id}
        className={styles.select}
        onChange={(e) => {
          const { value } = e.target;
          onChange(value);
        }}
      >
        {options.map(({ label, value }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

interface i_checkBox {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const ContactForm_CheckBox: React.FC<i_checkBox> = ({
  label,
  onChange,
  checked,
}) => {
  return (
    <div className={styles.checkBoxWrapper}>
      <span className={styles.checkBoxLabel}>{label}</span>
      <input
        checked={checked}
        type="checkbox"
        className={styles.checkBox}
        onChange={(e) => onChange(e.target.checked)}
      />
    </div>
  );
};
