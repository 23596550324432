// extracted by mini-css-extract-plugin
export var btnBack = "P_fj";
export var btnClose = "P_fk";
export var closeIcon = "P_fl";
export var errorDisplay = "P_fn";
export var flexColumn = "P_ff";
export var headingWrapper = "P_fp";
export var navButtons = "P_fm";
export var page = "P_b";
export var pageContent = "P_fr";
export var pageHeader = "P_fg";
export var pageHeaderButton = "P_fh";
export var pageHeaderHeading = "P_fq";