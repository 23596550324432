import React, { useState } from "react";
import * as styles from "./index.module.scss";
import { PageWrapper } from "../../components/page-wrapper";
import { e_contactVariant, i_contactFormPage, t_formData } from "../../types";
import { useFormat } from "../../../../../../hooks/useFormat";
import {
  getVariantPostFix,
  transPrefix,
} from "../../../../../../utils/helpers";
import { Form_SubHeading } from "../../components/subheading";
import { ContactForm_ContactField } from "../../components/contact-field";
import { FormattedMessage } from "gatsby-plugin-intl";
import { e_contactFormPages } from "../../index";
import { DATA_SELLERS, DATA_SELLER_INDEXES } from "../../sellers";

const pagePrefix = transPrefix("contact-form_leaveContact");

interface i_contactProps extends i_contactFormPage {
  variant: e_contactVariant;
}

function getVariantSuccessPage(variant: e_contactVariant) {
  switch (variant) {
    case e_contactVariant.SELLER_B2C:
      return e_contactFormPages.SUCCESS_SELLER_B2C;
    case e_contactVariant.ALBIXON_B2B:
      return e_contactFormPages.SUCCESS_ALBIXON_B2B;
    case e_contactVariant.ALBIXON_ZO:
      return e_contactFormPages.SUCCESS_ABZO;
    case e_contactVariant.NO_SELLER:
      return e_contactFormPages.SUCCESS_NO_SELLER;
  }
}

function getVariantSeller(variant: e_contactVariant, data: t_formData) {
  switch (variant) {
    case e_contactVariant.SELLER_B2C:
      break;
    case e_contactVariant.ALBIXON_B2B:
      data[0].contactSeller = DATA_SELLERS[DATA_SELLER_INDEXES.b2b];
      break;
    case e_contactVariant.ALBIXON_ZO:
      data[0].contactSeller = DATA_SELLERS[DATA_SELLER_INDEXES.abzo];
      break;
    case e_contactVariant.NO_SELLER:
      data[0].contactSeller = DATA_SELLERS[DATA_SELLER_INDEXES.abzo];
      break;
  }

  return data;
}

export const ContactForm_Contact: React.FC<i_contactProps> = ({
  variant,
  page,
  data,
}) => {
  const { formatString } = useFormat(pagePrefix);
  const [localError, setLocalError] = useState("");
  const [formData] = data;
  const displayLang = variant !== e_contactVariant.SELLER_B2C;

  return (
    <PageWrapper
      closeHandler={page.close}
      contentClassName={styles.wrapperContent}
      headerClassName={styles.header}
      heading={formatString(`heading${getVariantPostFix(variant)}`)}
      backButtonHandler={page.back}
      subHeading={
        <Form_SubHeading>
          <FormattedMessage
            id={pagePrefix(`subHeading${getVariantPostFix(variant)}`)}
            values={{ seller: formData.contactSeller?.name }}
          />
        </Form_SubHeading>
      }
      error={localError ? localError : ""}
    >
      <ContactForm_ContactField
        page={page}
        lang={displayLang}
        b2b={variant === e_contactVariant.ALBIXON_B2B}
        data={getVariantSeller(variant, data)}
        successPage={getVariantSuccessPage(variant)}
        localErrorState={[localError, setLocalError]}
      />
    </PageWrapper>
  );
};
