import React, { useEffect, useState } from "react";
import { useFormat } from "../../hooks/useFormat";
import { checkTouchDevice, isBrowser, throttle } from "../../utils/helpers";

function getScrollPosition() {
  return isBrowser() ? window.scrollY : 0;
}

function getViewportHeight() {
  return isBrowser() ? window.innerHeight : 0;
}

function getViewportWidth() {
  return isBrowser() ? window.innerWidth : 0;
}

function checkJs() {
  return isBrowser();
}

export const PageStateContext = React.createContext({
  isTouchDevice: checkTouchDevice(),
  viewportHeight: getViewportHeight(),
  viewportWidth: getViewportWidth(),
  runsClientJs: checkJs(),
});

export const ScrollContext = React.createContext({
  scrollY: getScrollPosition(),
});

export const ScrollStateProvider: React.FC = ({ children }) => {
  const [scrollY, setScrollY] = useState(getScrollPosition());

  const handleScroll = throttle(() => {
    setScrollY(getScrollPosition());
  }, 40);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    setScrollY(getScrollPosition());

    return function () {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollContext.Provider value={{ scrollY }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const PageStateProvider: React.FC = ({ children }) => {
  const { intl } = useFormat();
  const [viewportHeight, setViewportHeight] = useState(getViewportHeight());
  const [viewportWidth, setViewportWidth] = useState(getViewportWidth());
  const [isTouchDevice, setIsTouchDevice] = useState(checkTouchDevice());
  const [runsClientJs, setRunsJsClient] = useState(checkJs());

  const handleResize = throttle(() => {
    setViewportHeight(getViewportHeight());
    setViewportWidth(getViewportWidth());
  }, 500);

  useEffect(() => {
    document.body.setAttribute("data-lang", intl.locale);
  }, [intl]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, { passive: true });

    setViewportHeight(getViewportHeight());
    setViewportWidth(getViewportWidth());
    setIsTouchDevice(checkTouchDevice());
    setRunsJsClient(checkJs());

    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageStateContext.Provider
      value={{
        isTouchDevice,
        viewportHeight,
        viewportWidth,
        runsClientJs,
      }}
    >
      {children}
    </PageStateContext.Provider>
  );
};
