import { createAnimations, i_ledAnimation, i_poi } from "./data";
import { isBrowser } from "../../../../utils/helpers";

export class PoiAnimation {
  start: number;
  end: number;
  midTime: number;
  isOpening: boolean;
  finished: boolean;
  camera: (isTouchDevice: boolean) => (string | number)[] | null | string;
  target: (isTouchDevice: boolean) => (string | number)[] | null | string;

  constructor(data: i_animationData) {
    const { midTime } = getAnimData(data);
    this.start = data.start;
    this.end = data.end;
    this.midTime = midTime;
    this.isOpening = true;
    this.finished = true;
    this.camera = data.camera;
    this.target = data.target;
  }
}

export interface i_animationData {
  start: number;
  end: number;
  camera: (isTouchDevice: boolean) => (string | number)[] | null | string;
  target: (isTouchDevice: boolean) => (string | number)[] | null | string;
}

export function defaultFieldOfView(isTouchDevice: boolean) {
  return "25deg";
}

const mobileCameraSizes = [
  "0deg 80deg 1.8m",
  700,
  "0deg 80deg 2m",
  900,
  "0deg 80deg 2.4m",
];

const desktopCameraSizes = [
  "0deg 80deg 4m",
  700,
  "0deg 80deg 2.5m",
  900,
  "0deg 80deg 2m",
  1200,
  "0deg 80deg 1.5m",
];

export function defaultCamera(isTouchDevice: boolean) {
  const sizes = isTouchDevice ? mobileCameraSizes : desktopCameraSizes;

  return getResponsiveValue([...sizes], isTouchDevice);
}

const mobileTargetSizes = ["0m 0.1m 0m"];

const desktopTargetSizes = ["0m 0.05m 0m"];

export function defaultTarget(isTouchDevice: boolean) {
  const sizes = isTouchDevice ? mobileTargetSizes : desktopTargetSizes;

  return getResponsiveValue([...sizes], isTouchDevice);
}

export const animation = (() => {
  let lastPoi: i_poi | undefined;

  function clear(
    setNexActivePoi: (poi: i_poi | undefined) => void,
    setViewOffset: (visible: boolean) => void
  ) {
    lastPoi = undefined;
    setViewOffset(false);
    setNexActivePoi(undefined);
  }

  return {
    clear,
    handle: (
      poi: i_poi,
      viewerRef: any,
      setNexActivePoi: (poi: i_poi | undefined) => void,
      setViewOffset: (visible: boolean) => void,
      isTouchDevice: boolean
    ) => {
      const el = viewerRef.current;

      if (lastPoi) {
        if (poi !== lastPoi) {
          animateToClosed(
            lastPoi.animation,
            el,
            () => {
              setNexActivePoi(undefined);
            },
            () => {
              lastPoi = poi;
              animateToOpened(
                poi.animation,
                el,
                () => {
                  setViewOffset(true);
                  setNexActivePoi(poi);
                  animateTarget(
                    el,
                    poi.animation.target(isTouchDevice) ||
                      defaultTarget(isTouchDevice),
                    isTouchDevice
                  );
                  animateCamera(
                    el,
                    poi.animation.camera(isTouchDevice) ||
                      defaultCamera(isTouchDevice),
                    isTouchDevice
                  );
                },
                () => {}
              );
            }
          );
        } else {
          if (poi.animation.isOpening) {
            animateToClosed(
              poi.animation,
              el,
              () => {
                setViewOffset(false);
                setNexActivePoi(undefined);
                animateTarget(el, defaultTarget(isTouchDevice), isTouchDevice);
                animateCamera(el, defaultCamera(isTouchDevice), isTouchDevice);
              },
              () => {
                lastPoi = undefined;
              }
            );
          } else {
            animateToOpened(
              poi.animation,
              el,
              () => {
                setViewOffset(true);
                setNexActivePoi(poi);
                animateTarget(
                  el,
                  poi.animation.target(isTouchDevice) ||
                    defaultTarget(isTouchDevice),
                  isTouchDevice
                );
                animateCamera(
                  el,
                  poi.animation.camera(isTouchDevice) ||
                    defaultCamera(isTouchDevice),
                  isTouchDevice
                );
              },
              () => {}
            );
          }
        }
      } else {
        animateToOpened(
          poi.animation,
          el,
          () => {
            setViewOffset(true);
            setNexActivePoi(poi);
            animateTarget(
              el,
              poi.animation.target(isTouchDevice) ||
                defaultTarget(isTouchDevice),
              isTouchDevice
            );
            animateCamera(
              el,
              poi.animation.camera(isTouchDevice) ||
                defaultCamera(isTouchDevice),
              isTouchDevice
            );
          },
          () => {}
        );
      }
      if (!lastPoi) {
        lastPoi = poi;
      }
    },
  };
})();

function getAnimData(anim: i_animationData) {
  const duration = anim.end - anim.start;
  const midTime = duration / 2 + anim.start;
  return { duration, midTime };
}

function getRemainingTime(anim: PoiAnimation, currentTime: number) {
  let remainingTime;
  if (anim.isOpening) {
    remainingTime = anim.midTime - currentTime;
  } else {
    remainingTime = anim.end - currentTime;
  }
  return remainingTime;
}

function flipTime(anim: PoiAnimation, timeToFlip: number) {
  return anim.end - (timeToFlip - anim.start);
}

function getResponsiveValue(
  value: string | (string | number)[],
  isTouchDevice: boolean
): string {
  if (isBrowser()) {
    const compareTo = isTouchDevice
      ? Math.max(window.innerWidth, window.innerHeight)
      : window.innerWidth;
    if (Array.isArray(value)) {
      for (let i = value.length - 1; i >= 0; i--) {
        const val = value[i];
        if (typeof val === "number") {
          if (val <= compareTo) {
            return value[i + 1] as string;
          }
        }
      }
      return value[0] as string;
    }
  }
  return value as string;
}

function animateCamera(
  el: any,
  orbit: string | (string | number)[],
  isTouchDevice: boolean
) {
  el.cameraOrbit = getResponsiveValue(orbit, isTouchDevice);
}

function animateTarget(
  el: any,
  target: string | (string | number)[],
  isTouchDevice: boolean
) {
  el.cameraTarget = getResponsiveValue(target, isTouchDevice);
}

let endTimeout: NodeJS.Timeout;
let midPointTimeout: NodeJS.Timeout;

function clearTimeouts() {
  clearTimeout(endTimeout);
  clearTimeout(midPointTimeout);
}

type t_startCallback = () => void;

function animate(
  el: any,
  anim: PoiAnimation,
  onEndCallback: () => void,
  endTime: number
) {
  anim.finished = false;
  const rt = getRemainingTime(anim, el.currentTime);
  el.play();

  return setTimeout(() => {
    el.pause();
    el.currentTime = endTime;
    anim.finished = true;
    onEndCallback();
  }, rt * 1000);
}

// end is the closest animation time where the moover is closed (in default position)
function animateToClosed(
  anim: PoiAnimation,
  el: any,
  onStartCallback: t_startCallback | null,
  onEndCallback: () => void
) {
  clearTimeouts();
  if (onStartCallback) onStartCallback();
  // flip time for toggle functionality
  if (anim.isOpening) {
    el.currentTime = flipTime(anim, el.currentTime);
  }
  anim.isOpening = false;
  endTimeout = animate(el, anim, onEndCallback, anim.start);
}

function animateToOpened(
  anim: PoiAnimation,
  el: any,
  onStartCallback: () => void | null,
  onEndCallback: () => void
) {
  clearTimeouts();
  if (onStartCallback) onStartCallback();
  // flip time for toggle functionality
  if (!anim.isOpening && !anim.finished) {
    el.currentTime = flipTime(anim, el.currentTime);
  } else {
    el.currentTime = anim.start;
  }
  anim.isOpening = true;
  midPointTimeout = animate(el, anim, onEndCallback, anim.midTime);
}

//==================== LED ==========================//

export function ledAnimation(viewerData: any) {
  const modelData = viewerData.model;
  const animations = createAnimations();
  let animationInterval: any;
  let lights = extractLights(modelData.materials);
  viewerData.currentTime = 0;
  viewerData.pause();

  function animate(animation: i_ledAnimation, onAnimationEnd: () => void) {
    clearInterval(animationInterval);
    animationInterval = animateLED(lights, animations, animation, () => {
      if (animation.noLoop) {
        onAnimationEnd();
        clear();
        return true;
      }
      return false;
    });
  }

  function clear() {
    clearInterval(animationInterval);
    applyLightMatrix(
      lights,
      Float32Array.from(
        JSON.parse(
          "[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]"
        )
      ),
      [0, 0, 0],
      0
    );
  }

  function updateViewOnScreenChange(
    poi: i_poi,
    viewerRef: any,
    isTouchDevice: boolean
  ) {
    const el = viewerRef.current;
    animateTarget(
      el,
      poi.animation.target(isTouchDevice) || defaultTarget(isTouchDevice),
      isTouchDevice
    );
    animateCamera(
      el,
      poi.animation.camera(isTouchDevice) || defaultCamera(isTouchDevice),
      isTouchDevice
    );
  }

  return {
    updateViewOnScreenChange,
    animate,
    clear,
  };
}

function extractLights(materials: any[]) {
  const selectedMaterials: any = [];

  materials.forEach((mat: any) => {
    const matSymbols = Object.getOwnPropertySymbols(mat);
    const lookupSymbol = matSymbols.find(
      (symbol) => String(symbol) === "Symbol(sourceObject)"
    );

    if (lookupSymbol) {
      const symbolData = mat[lookupSymbol];
      if (symbolData) {
        if (symbolData.name.toLowerCase().includes("dioda")) {
          selectedMaterials.push(mat);
        }
      }
    }
  });

  return selectedMaterials;
}

function animateLED(
  lights: any[],
  animations: any,
  animation: i_ledAnimation,
  onAnimationLoop: () => boolean
) {
  let row = 0;
  const LEDsPerRow = 60;
  const animationData = animations[animation.matrix];
  const rowCount = animationData.length / LEDsPerRow;
  return setInterval(() => {
    if (row === rowCount) {
      if (onAnimationLoop()) return;
      row = 0;
    }
    const LEDRowIndex = row * LEDsPerRow;
    applyLightMatrix(lights, animationData, animation.color, LEDRowIndex);
    row++;
  }, 1000 / 24);
}

function applyLightMatrix(
  lights: any[],
  matrix: Float32Array,
  color:
    | [number, number, number]
    | ((index: number) => [number, number, number]),
  rowIndex: number
) {
  for (let i = 0; i < lights.length; i++) {
    const light = lights[i];

    if (typeof color === "function") {
      if (rowIndex === undefined) {
        throw new Error(
          "Row index need to be provided when color prop is function"
        );
      }
      color = color(rowIndex + i);
    }

    light.pbrMetallicRoughness.setBaseColorFactor([
      ...color,
      matrix[rowIndex + i],
    ]);
  }
}
