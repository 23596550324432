import React, { useContext, useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { Container } from "../../../../components/container";
import { Translate } from "../../../../components/translate";
import { H_2, H_3, H_4 } from "../../../../components/typography/heading";
import {
  e_textAlign,
  e_textTag,
  TextSmall,
} from "../../../../components/typography/text";
import { cls, navPrefix } from "../../../../utils/helpers";
import Img from "gatsby-image";
import { useFormat } from "../../../../hooks/useFormat";
import { staticFile } from "../../../../config";
import { PageStateContext } from "../../../../components/page-state-provider";
import { getResponsiveImage } from "../../../../utils/query";
import {
  compPrefix,
  i_poi,
  ledAnimations,
  pointsOfInterest,
  useImageDataQuery,
} from "./data";
import {
  animation,
  defaultCamera,
  defaultFieldOfView,
  defaultTarget,
  ledAnimation,
} from "./animation";
import { AnimatedMount } from "../../../../components/animated-mount";
import { useManagedClass } from "../../../../hooks/useManagedClass";
import { SECTIONS } from "../navbar";
import CloseIcon from "../../../../assets/icons/close.svg";
import FlipIcon from "../../../../assets/icons/flip.svg";

export const Index_View3D: React.FC<{
  currentSection: number | null;
}> = ({ currentSection }) => {
  const { formatString } = useFormat(compPrefix);
  const { isTouchDevice } = useContext(PageStateContext);
  const imgData = useImageDataQuery(formatString("poster", null));
  const { ref } = useManagedClass<HTMLDivElement>(isTouchDevice, styles.touch);
  const [interacted, setInteracted] = useState(false);
  const [hasMounted, setHasMounted] = React.useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <section>
      <div
        className={styles.rootWrapper}
        id={formatString("link2Anchor", navPrefix)}
      >
        <Container pt>
          <H_4 tag={e_textTag.p} textAlign={e_textAlign.CENTER}>
            <Translate id={compPrefix("h4")} />
          </H_4>
          <H_2
            tag={e_textTag.p}
            textAlign={e_textAlign.CENTER}
            className={styles.heading}
          >
            <Translate id={compPrefix("h1")} />
          </H_2>
          <TextSmall align={e_textAlign.CENTER} className={styles.description}>
            <Translate id={compPrefix("description")} />
          </TextSmall>
        </Container>
        <div
          className={styles.container}
          ref={ref}
          onPointerDown={() => setInteracted(true)}
        >
          {!hasMounted ? null : isTouchDevice ? (
            <MobileModelViewer
              interacted={interacted}
              currentSection={currentSection}
              imgData={imgData}
            />
          ) : (
            <ModelViewer
              interacted={interacted}
              currentSection={currentSection}
              poster={getResponsiveImage(imgData)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

interface i_mobileModelViewerProps {
  imgData: any;
  interacted: boolean;
  currentSection: number | null;
}

const MobileModelViewer: React.FC<i_mobileModelViewerProps> = ({
  imgData,
  interacted,
  currentSection,
}) => {
  const [opened, setOpened] = useState(false);
  const [VW, setVW] = useState(window.innerWidth);
  const [VH, setVH] = useState(window.innerHeight);
  const [rotatePromptVisible, setRotatePromptVisible] = useState(false);

  const height = VH > VW ? VW : VH;
  const width = VH > VW ? VH : VW;
  const transformClass = VH > VW && styles.rotated;

  useEffect(() => {
    window.addEventListener("resize", () => {
      setVW(window.innerWidth);
      setVH(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    if (opened) {
      document.body.classList.add(styles.noOverFlow);
    } else {
      document.body.classList.remove(styles.noOverFlow);
    }
  }, [opened]);

  useEffect(() => {
    if (opened) {
      if (rotatePromptVisible) {
        setRotatePromptVisible(window.innerHeight > window.innerWidth);
      } else {
        setTimeout(() => {
          setRotatePromptVisible(window.innerHeight > window.innerWidth);
        }, 10000);
      }
    }
  }, [opened, VH, VW]);

  return (
    <>
      <div onClick={() => setOpened(true)} className={styles.mobileWrapper}>
        <Img
          className={styles.modelImgPlaceholder}
          alt={""}
          fluid={imgData.childImageSharp.fluid}
        />

        <H_3 className={styles.startBtn} textAlign={e_textAlign.CENTER}>
          <Translate id={compPrefix("start")} />
        </H_3>
      </div>
      {opened && (
        <div
          className={cls(styles.modal, transformClass)}
          style={{ width, height, zIndex: 100 }}
        >
          <div className={styles.closeBtn} onClick={() => setOpened(false)}>
            <CloseIcon />
          </div>

          <div
            className={cls(
              styles.rotatePrompt,
              rotatePromptVisible && styles.rotatePromptVisible
            )}
          >
            <div className={styles.rotatePromptContent}>
              <FlipIcon />
              <p>
                <Translate id={compPrefix("flipPhone")} />
              </p>
            </div>
          </div>

          <ModelViewer
            interacted={interacted}
            currentSection={currentSection}
            poster={getResponsiveImage(imgData)}
          />
        </div>
      )}
    </>
  );
};

interface i_modelViewerProps {
  poster: string;
  interacted: boolean;
  currentSection: number | null;
}

const initialLED = 4;
const ModelViewer: React.FC<i_modelViewerProps> = ({
  poster,
  interacted,
  currentSection,
}) => {
  const viewerRef = useRef<any>(null);
  const ledAnimationRef = useRef<any>();
  const { formatString } = useFormat(compPrefix);
  const [activePoi, setActivePoi] = useState<i_poi | undefined>();
  const [nextActivePoi, setNexActivePoi] = useState<i_poi | undefined>();
  const [viewOffset, setViewOffset] = useState(false);
  const { viewportWidth, isTouchDevice } = useContext(PageStateContext);
  const [loaded, setLoaded] = useState(false);
  const [activeLED, setActiveLED] = useState<null | number>(
    !isTouchDevice ? initialLED : null
  );

  useEffect(() => {
    const el = viewerRef.current;
    el.addEventListener("load", () => {
      ledAnimationRef.current = ledAnimation(el);
      setLoaded(true);
      if (!isTouchDevice) {
        el.interactionPromptThreshold = 1;
        el.resetInteractionPrompt();
        ledAnimationRef.current.animate(ledAnimations[initialLED], () => {});
      }
    });

    return function () {
      animation.clear(setNexActivePoi, setViewOffset);
    };
  }, []);

  useEffect(() => {
    if (
      currentSection !== SECTIONS.INSTALLATION &&
      currentSection !== SECTIONS.MODEL &&
      currentSection !== SECTIONS.BENEFITS
    ) {
      if (interacted) {
        if (ledAnimationRef.current) {
          ledAnimationRef.current.clear();
          setActiveLED(null);
        }
      }
    }
  }, [currentSection, interacted]);

  function handlePoiClick(poi: i_poi) {
    if (viewerRef.current) {
      viewerRef.current.interactionPrompt = "none";
    }
    animation.handle(
      poi,
      viewerRef,
      setNexActivePoi,
      setViewOffset,
      isTouchDevice
    );
  }

  const currentPoi = nextActivePoi || activePoi;

  useEffect(() => {
    if (ledAnimationRef.current && currentPoi) {
      if (currentPoi.name !== "hotspot_ledName") {
        ledAnimationRef.current.clear();
        setActiveLED(null);
      }
    }
  }, [nextActivePoi, activePoi]);

  useEffect(() => {
    const el = viewerRef.current;
    if (el) {
      if (currentPoi) {
        if (ledAnimationRef.current) {
          ledAnimationRef.current.updateViewOnScreenChange(
            currentPoi,
            viewerRef,
            isTouchDevice
          );
        }
      } else {
        el.cameraOrbit = defaultCamera(isTouchDevice);
        el.cameraTarget = defaultTarget(isTouchDevice);
      }
      el.fieldOfView = defaultFieldOfView(isTouchDevice);
    }
  }, [viewportWidth, viewerRef, ledAnimationRef]);

  useEffect(() => {
    const el = viewerRef.current;

    if (el) {
      if (viewportWidth < 900) {
        if (viewOffset) {
          el.style.transform = "translateX(25%) scale(1.5)";
          el.fieldOfView = "50deg";
        } else {
          el.style.transform = "scale(1) translateX(0)";
          el.fieldOfView = "25deg";
        }
      } else {
        el.style.transform = "scale(1) translateX(0)";
        el.fieldOfView = "25deg";
      }
    }
  }, [viewportWidth, viewerRef, viewOffset]);

  return (
    <>
      <Container
        className={cls(
          styles.descriptionContainer,
          isTouchDevice && styles.descriptionTouch
        )}
      >
        <AnimatedMount
          mounted={!!nextActivePoi}
          wrapperClass={styles.poiDescription}
          onActionEnd={() => {
            setActivePoi(nextActivePoi);
          }}
        >
          {currentPoi && (
            <>
              <div
                className={styles.closeDetail}
                onClick={() => handlePoiClick(currentPoi)}
              >
                <Translate id={compPrefix("close")} />
              </div>
              <H_3 className={styles.name}>
                <Translate id={compPrefix(currentPoi.name)} />
              </H_3>
              <TextSmall>
                <Translate id={compPrefix(currentPoi.description)} />
              </TextSmall>
              {currentPoi.name === "hotspot_ledName" && (
                <LEDs
                  activeLED={activeLED}
                  setActiveLED={setActiveLED}
                  animationHandler={ledAnimationRef.current}
                />
              )}
            </>
          )}
        </AnimatedMount>
      </Container>
      {/*@ts-ignore*/}
      <model-viewer
        src={staticFile("/moover.glb")}
        poster={poster}
        alt={formatString("modelAlt")}
        ar={false}
        ref={viewerRef}
        id={"3Dviewer"}
        camera-controls
        autoplay={false}
        field-of-view={"25deg"}
        disable-zoom={true}
        camera-orbit={defaultCamera(isTouchDevice)}
        camera-target={defaultTarget(isTouchDevice)}
        max-field-of-view={"150deg"}
        min-field-of-view={"25deg"}
        min-camera-orbit="auto 60deg 0.5m"
        max-camera-orbit="auto 110deg 10m"
        data-js-focus-visible
        ar-status
        style={{
          height: "100%",
          width: "100%",
          transition: " 0.17s cubic-bezier(0.45, 0.43, 0.58, 1)",
          touchAction: "pan-y pan-x pinch-zoom",
        }}
      >
        {pointsOfInterest.map((poi) => {
          return (
            <button
              className={cls(
                styles.hotspotBtn,
                currentPoi === poi && styles.active,
                !loaded && styles.btnLoading
              )}
              title={formatString(poi.name)}
              slot={poi.slotName}
              key={poi.slotName}
              data-position={poi.position}
              data-normal={poi.normal}
              data-visibility-attribute="visible"
              onClick={() => handlePoiClick(poi)}
              style={{
                transform:
                  viewOffset && viewportWidth < 900
                    ? "translate3d(0,0,5px) scale(0.75)"
                    : "",
              }}
            >
              {currentPoi === poi && <CloseIcon className={styles.closePoi} />}
            </button>
          );
        })}
	  {/*@ts-ignore*/}
      </model-viewer>
    </>
  );
};

const LEDs: React.FC<{
  animationHandler: any;
  activeLED: number | null;
  setActiveLED: (state: number | null) => void;
}> = ({ animationHandler, activeLED, setActiveLED }) => {
  return (
    <div className={styles.leds}>
      {ledAnimations.map((animation, index) => {
        return (
          <div
            key={index}
            className={cls(styles.led, index === activeLED && styles.activeLed)}
            onClick={() => {
              if (animation) {
                if (activeLED === index) {
                  animationHandler.clear();
                  setActiveLED(null);
                } else {
                  setActiveLED(index);
                  animationHandler.animate(animation, () => {
                    setActiveLED(null);
                  });
                }
              }
            }}
          >
            <Translate id={compPrefix(animation.name)} />
          </div>
        );
      })}
    </div>
  );
};
