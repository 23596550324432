import React, { ReactElement } from "react";
import { i_defaultComponentProps } from "../../../../../../utils/interfaces";
import { cls, transPrefix } from "../../../../../../utils/helpers";
import * as styles from "./index.module.scss";
import { Translate } from "../../../../../../components/translate";
import { H_3 } from "../../../../../../components/typography/heading";
import { e_textAlign } from "../../../../../../components/typography/text";
import ChevronIcon from "../../../../../../assets/icons/chevron.svg";
import CloseIcon from "../../../../../../assets/icons/close.svg";

const prefix = transPrefix("contact-form");

interface i_contactFormPageWrapperProps extends i_defaultComponentProps {
  heading?: string;
  subHeading?: ReactElement | undefined;
  headerClassName?: string;
  contentClassName?: string;
  backButtonHandler?: () => void;
  closeHandler?: () => void;
  error?: string;
}

export const PageWrapper: React.FC<i_contactFormPageWrapperProps> = ({
  heading,
  subHeading,
  children,
  className,
  contentClassName,
  headerClassName,
  backButtonHandler,
  closeHandler,
  error = "",
}) => {
  return (
    <div className={cls(styles.page, styles.flexColumn, className)}>
      <div className={cls(styles.pageHeader, headerClassName)}>
        <div className={styles.navButtons}>
          {backButtonHandler && (
            <button
              className={cls(styles.pageHeaderButton, styles.btnBack)}
              onClick={backButtonHandler}
            >
              <ChevronIcon />
              <Translate id={prefix("back")} />
            </button>
          )}
          <div />
          {closeHandler && (
            <button
              className={cls(
                styles.pageHeaderButton,
                styles.closeIcon,
                styles.btnClose
              )}
              onClick={closeHandler}
            >
              <CloseIcon />
            </button>
          )}
        </div>

        <div className={cls(styles.headingWrapper, styles.flexColumn)}>
          {heading && (
            <H_3
              className={styles.pageHeaderHeading}
              textAlign={e_textAlign.CENTER}
            >
              {heading}
            </H_3>
          )}
          {subHeading && subHeading}
        </div>
      </div>
      {error && <div className={styles.errorDisplay}>{error}</div>}
      <div className={cls(styles.pageContent, contentClassName)}>
        {children}
      </div>
    </div>
  );
};
