import React, {useEffect, useState} from "react";
import * as styles from "./index.module.scss";
import { Link } from "gatsby";
import { e_textTransform, Text } from "../typography/text";
import { cls, isLanguageActive, iterateLanguages } from "../../utils/helpers";

interface i_langSelectProps {
  path: string;
}

export const LangSelect: React.FC<i_langSelectProps> = ({ path }) => {
  const [activeLangIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    iterateLanguages((lang, index) => {
      if (isLanguageActive(lang)) setActiveIndex(index);
    });
  }, []);

  return (
    <div className={styles.langSelect}>
      {/* Change language via Gatsby's <Link /> */}
      {iterateLanguages((lang, index) => {
        return (
          <Link key={index} className={styles.lang} to={`/${lang}${path}`}>
            <Text
              className={cls(
                styles.langName,
                index === activeLangIndex && styles.active
              )}
              textTransform={e_textTransform.UPPERCASE}
            >
              {lang}
            </Text>
          </Link>
        );
      })}
    </div>
  );
};
