//@refactored
import React, { useContext, useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { LangSelect } from "../../../../components/lang-select";
import { Container } from "../../../../components/container";
import {
  PageStateContext,
  ScrollContext,
} from "../../../../components/page-state-provider";
import {
  clamp,
  cls,
  getAnimationProgress,
  transPrefix,
} from "../../../../utils/helpers";
import Video from "../../../../assets/videos/moover.mp4";
import { useFormat } from "../../../../hooks/useFormat";
import { Translate } from "../../../../components/translate";
import MooverLogo from "../../../../assets/icons/moover-logo.svg";
import ArrowIcon from "../../../../assets/icons/arrow.svg";
import PauseIcon from "../../../../assets/icons/pause.svg";
import HandleArrow from "../../../../assets/arrow-handle-new.svg";

interface i_heroHeaderProps {
  updateAnimationProgress: (progress: number) => void;
  currentSection: number | null;
}

const compPrefix = transPrefix("hero-header");

export const Index_HeroHeader: React.FC<i_heroHeaderProps> = ({
  updateAnimationProgress,
  currentSection,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const videoElementRef = useRef<HTMLVideoElement | null>(null);

  const { viewportHeight, isTouchDevice } = useContext(PageStateContext);
  const { scrollY } = useContext(ScrollContext);
  const { formatString } = useFormat(compPrefix);

  const [animationProgress, setAnimationProgress] = useState(0);
  const [controlsState, setControlsState] = useState("initial");
  const coverBarsRefs = useRef<(HTMLDivElement | null)[]>([]);
  const langBarRef = useRef<HTMLDivElement | null>(null);

  function handleVideoToggle() {
    const video = videoElementRef.current;
    if (video) {
      controlsState !== "playing" ? video.play() : video.pause();
      setControlsState(controlsState !== "playing" ? "playing" : "paused");
    }
  }

  useEffect(() => {
    const video = videoElementRef.current;
    if (video) {
      video
        .play()
        .then(() => setControlsState("playing"))
        .catch(() => setControlsState("paused"));
    }
  }, [videoElementRef]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (!isTouchDevice && viewportHeight > 500) {
        wrapper.classList.add(styles.notTouch);
      } else {
        wrapper.classList.remove(styles.notTouch);
      }
    }
  }, [viewportHeight, isTouchDevice]);

  useEffect(() => {
    const progress = getAnimationProgress(0, window.innerHeight / 2, scrollY);
    updateAnimationProgress(progress);
    setAnimationProgress(progress);

    if (!isTouchDevice) {
      coverBarsRefs.current.forEach((bar, index) => {
        if (bar) {
          if (index === 0) {
            bar.style.transform = `scale3d(1,${1 - progress},1)`;
          } else {
            bar.style.transform = `scale3d(${1 - progress},1,1)`;
          }
        }

        const langBar = langBarRef.current;
        if (langBar) {
          const height = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue("--bottom-bar-height")
            .replace("rem", "");
          langBar.style.transform = `translate3d(0,${
            progress * Number(height)
          }rem,0)`;
        }
      });
    }
  }, [scrollY, isTouchDevice, currentSection]);

  return (
    <>
      <div ref={wrapperRef} className={styles.wrapper}>
        <div className={styles.anchor} id={"top"} />
        <div className={styles.contentWrapper}>
          <div className={styles.videoWrapper}>
            <video
              className={styles.video}
              autoPlay
              muted
              playsInline
              disablePictureInPicture
              loop
              preload={"auto"}
              ref={videoElementRef}
            >
              <source src={Video} type="video/mp4" />
            </video>
            {Array.from(new Array(3)).map((item, index) => (
              <div
                key={index}
                className={styles[`coverBar${index + 1}`]}
                ref={(e) => (coverBarsRefs.current[index] = e)}
              />
            ))}
            <div
              className={styles.videoOverlay}
              style={{ opacity: 1 - animationProgress }}
            />
            <div className={styles.content}>
              <MooverLogo
                className={styles.logo}
                style={{ opacity: 1 - clamp(0, 1, animationProgress * 1.4) }}
              />
              <button
                className={cls(styles.playBtn, styles.mobile)}
                onClick={handleVideoToggle}
                title={"Play/Pause Video"}
              >
                {controlsState === "paused" ? <ArrowIcon /> : <PauseIcon />}
              </button>
            </div>
            <div
              className={cls(
                styles.controls,
                controlsState === "paused" ? styles.paused : styles.playing
              )}
            >
              <button
                className={styles.playBtn}
                onClick={handleVideoToggle}
                title={"Play/Pause Video"}
              >
                {controlsState === "paused" ? <ArrowIcon /> : <PauseIcon />}
              </button>
            </div>
          </div>
          <div ref={langBarRef} className={styles.langBar}>
            <HandleArrow className={styles.handleArrow} />
            <Container
              className={styles.container}
              style={{ opacity: 1 - animationProgress }}
            >
              <a
                title={formatString("linkTitle")}
                target={"_blank"}
                rel="noopener noreferrer"
                href={`https://${formatString("website")}`}
              >
                <Translate id={compPrefix("website")} />
              </a>
              <LangSelect path={"/"} />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
