import { e_supportLinkType } from "../../../../components/panel";
import { transPrefix } from "../../../../utils/helpers";

export const compPrefix = transPrefix("support");

export const content:  {
  handler: string;
  type: e_supportLinkType;
}[] = [
  {
    handler: "fileManual",
    type: e_supportLinkType.Download,
  },
  {
    handler: "fileProductList",
    type: e_supportLinkType.Download,
  },
];
