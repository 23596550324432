const missingVarMsg = (varName: string) => `Missing ${varName} env variable`;

export function getEmailEndpoint() {
  const emailHost = process.env.GATSBY_EMAIL_SERVICE_URL;

  if (!emailHost) {
    throw new Error(missingVarMsg("GATSBY_EMAIL_SERVICE_URL"));
  }

  return emailHost;
}

export function getCaptchaKey() {
  const captchaKey = process.env.GATSBY_CAPTCHA_PUBLIC_V2;

  if (!captchaKey) {
    throw new Error(missingVarMsg("GATSBY_CAPTCHA_PUBLIC_V2"));
  }

  return { v2: captchaKey };
}

export function getMapsAPIKey() {
  const apiKey = process.env.GATSBY_MAPS_API_KEY;

  if (!apiKey) {
    throw new Error(missingVarMsg("GATSBY_MAPS_API_KEY"));
  }

  return apiKey;
}

export function staticFile(path: string) {
  const staticDest = "/static";
  return staticDest + path;
}
