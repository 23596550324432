import { COUNTRY_CODES, e_sellerType, i_seller } from "./types";
import { loadScript } from "../../../../utils/resources";
import { getMapsAPIKey } from "../../../../config";

export interface i_location {
  lat: () => number;
  lng: () => number;
}

export interface i_locationWithCountry extends i_location {
  country: string;
}

export interface i_mapsLocation {
  address_components: { long_name: string; short_name: string; types: any[] }[];
  formatted_address: string;
  geometry: {
    location: i_location;
    viewport: {
      Qa: {
        i: number;
        j: number;
      };
      Wa: {
        i: number;
        j: number;
      };
    };
  };
  name: string;
  utc_offset: number;
  utc_offset_minutes: number;
}

export type t_geoCodedLocation = Pick<
  i_mapsLocation,
  "address_components" | "geometry" | "formatted_address"
>;

export interface i_mapsApi {
  initializeMaps: () => Promise<i_mapsApi>;
  setupAutoComplete: (
    input: HTMLInputElement,
    selectCallback: (place: i_mapsLocation) => void
  ) => void;
  getLocationFromLatLng: (
    lat: number,
    lng: number
  ) => Promise<t_geoCodedLocation>;
  getLocationFromPlaceId: (placeId: string) => Promise<t_geoCodedLocation>;
  setupMap: (mapWrapper: HTMLDivElement, center: i_location) => void;
  addMarkerToMap: (target: i_location) => void;
  updateMap: (center: i_location) => void;
  updateMarker: (center: i_location) => void;
  getSellersInCountry: (
    targetLocation: i_locationWithCountry,
    allSellers: i_seller[]
  ) => Promise<i_seller[]>;
  getGoogleApi: () => t_GoogleApi;
}

type t_GoogleApi = any;
type t_Geocoder = any;
type t_Map = any;
type t_Marker = any;

export function convertCoordToCenter(coord: {
  lat: number;
  lng: number;
}): i_location {
  return {
    lat: () => coord.lat,
    lng: () => coord.lng,
  };
}

export const MapsApi: i_mapsApi = (() => {
  let googleApi: t_GoogleApi;
  let geocoder: t_Geocoder, map: t_Map, marker: t_Marker;
  let mapsApi: i_mapsApi = {
    initializeMaps,
    setupAutoComplete,
    getLocationFromLatLng,
    getLocationFromPlaceId,
    setupMap,
    addMarkerToMap,
    getSellersInCountry,
    updateMarker,
    updateMap,
    getGoogleApi,
  };
  const key = getMapsAPIKey();
  const scriptId = "googleMapsScript";

  async function initializeMaps() {
    await _initializeMaps(key, scriptId);
    if (!google) {
      throw new Error("Error while initializing Maps API");
    }
    googleApi = google.maps;
    return mapsApi;
  }

  function getGeoCoder() {
    if (!geocoder) {
      geocoder = new googleApi.Geocoder();
    }
    return geocoder;
  }

  async function getLocationFromLatLng(
    lat: number,
    lng: number
  ): Promise<t_geoCodedLocation> {
    const geocoder = getGeoCoder();
    const location = await _geocodeLatLng(lat, lng, geocoder);
    return location as t_geoCodedLocation;
  }

  // async function getLocationFromAddress(address: string) {
  //   const geocoder = getGeoCoder();
  //   const location = await _geocodeAddress(address, geocoder);
  //   return location as t_geoCodedLocation;
  // }

  async function getLocationFromPlaceId(placeId: string) {
    const geocoder = getGeoCoder();
    const location = await _geocodePlaceId(placeId, geocoder);
    return location as t_geoCodedLocation;
  }

  function setupAutoComplete(
    input: HTMLInputElement,
    selectCallback: (place: i_mapsLocation) => void
  ) {
    const autocomplete = new googleApi.places.Autocomplete(input, {});
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) selectCallback(place);
    });
  }

  function setupMap(mapWrapper: HTMLDivElement, center: i_location) {
    map = new googleApi.Map(mapWrapper, {
      zoom: 12,
      center,
    });
  }

  function addMarkerToMap(target: i_location) {
    marker = new googleApi.Marker({
      position: target,
      map: map,
    });
    return marker;
  }

  async function getSellersInCountry(
    targetLocation: i_locationWithCountry,
    allSellers: i_seller[]
  ) {
    return findSellersInCountry(targetLocation, allSellers, googleApi);
  }

  function updateMap(center: i_location) {
    if (!map) return;
    map.setCenter(center);
  }

  function updateMarker(center: i_location) {
    if (!marker) return;
    marker.setPosition(center);
  }

  function getGoogleApi() {
    return googleApi;
  }
  return mapsApi;
})();

const _initializeMaps = (() => {
  let error = false;
  return function (key: string, scriptId: string) {
    return new Promise((res, rej) => {
      loadScript(
        {
          id: scriptId,
          src: `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places`,
          async: true,
          reload: error,
        },
        () => {
          error = false;
          res({});
        },
        (e) => {
          error = true;
          rej(e);
        }
      );
    });
  };
})();

function findSellersInCountry(
  location: i_locationWithCountry,
  locations: i_seller[],
  api: t_GoogleApi
) {
  const userCountry =
    COUNTRY_CODES[location.country as keyof typeof COUNTRY_CODES];
  if (userCountry === undefined) return [];
  const filtered = [];
  for (const l of locations) {
    if (l.country === userCountry) {
      const coord = new api.LatLng(l.coord.lat, l.coord.lng);
      const distance =
        (Math.round(
          api.geometry.spherical.computeDistanceBetween(coord, {
			lat: location.lat(),
			lng: location.lng()
		  }) / 10
        ) *
          10) /
        1000;
      filtered.push({
        ...l,
        distance,
      });
    }
  }
  return filtered.sort((a, b) => a.distance - b.distance);
}

function _geocode(
  options:
    | { location: { lat: number; lng: number } }
    | { address: string }
    | { placeId: string },
  geocoder: t_Geocoder
) {
  return new Promise((res, rej) => {
    function handleResult(results: any, status: any) {
      if (status === "OK") {
        if (results[0]) {
          res(results[0]);
        } else {
          //no result
        }
      } else {
        rej();
        // failed cause of [status]
      }
    }
    geocoder.geocode(options, handleResult);
  });
}

async function _geocodeLatLng(lat: number, lng: number, geocoder: t_Geocoder) {
  return await _geocode({ location: { lat, lng } }, geocoder);
}

async function _geocodePlaceId(placeId: string, geocoder: t_Geocoder) {
  return await _geocode({ placeId }, geocoder);
}
