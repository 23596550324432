import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

interface i_imageProps {
  alt: string;
  name: string;
  className?: string;
}

export function filterImage(data: any, name: string, alt: string) {
  let selectedImage = data.allFile.edges.find(
    (img: any) => img.node.relativePath === name
  );

  if (!selectedImage) throw new Error(`Image '${name}' not found!`);

  selectedImage = selectedImage.node;

  if (!alt.length)
    throw new Error(
      `Image '${name}' had an empty alt value. Please include better alt value.`
    );

  return selectedImage;
}

export const Image: React.FC<i_imageProps> = ({ alt, name, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            base
            relativePath
            childImageSharp {
              fluid(maxWidth: 2000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  let selectedImage = filterImage(data, name, alt);

  return (
    <Img
      className={className}
      fluid={selectedImage.childImageSharp.fluid}
      alt={alt}
    />
  );
};
