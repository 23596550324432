import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Helmet from "react-helmet";
import { Page } from "../../components/page";
import { Index_Support } from "./components/support";
import { Index_FAQ } from "./components/faq";
import { Index_HeroHeader } from "./components/hero-header";
import {
  PageStateContext,
  PageStateProvider,
  ScrollStateProvider,
} from "../../components/page-state-provider";
import { Index_NavBar } from "./components/navbar";
import { Index_Gallery } from "./components/gallery";
import { Index_TryMoover } from "./components/try-moover";
import { Index_CtaButton } from "./components/cta-button";
import { Index_Introduction } from "./components/introduction";
import { Index_Benefits } from "./components/benefits";
import { Index_View3D } from "./components/view3D";
import { Index_Installation } from "./components/installation";
import {
  Index_ContactForm,
  ModalStateProvider,
} from "./components/contact-form";
import { staticFile } from "../../config";
import "../../lib/keen-slider/index.scss";
import { loadScript } from "../../utils/resources";
import { useFormat } from "../../hooks/useFormat";
import { transPrefix } from "../../utils/helpers";

let gallery: any;
const compPrefix = transPrefix("index");

const StyledIndexPage: React.FC = () => {
  useLayoutEffect(() => {
    loadScript(
      {
        id: "moduloBox",
        src: staticFile("/modulobox.min.js"),
        async: true,
      },
      handleLoad,
      () => {}
    );
    loadScript(
        {
          id: "cookie-consnet",
          src: staticFile("/cookie-consent.js"),
          async: false,
        },
        handleLoad,
        () => {}
    );

    function handleLoad() {
      const mobx = new ModuloBox({
        // options
        mediaSelector: ".mobx",
        scrollToZoom: true,
        loadError: "Sorry, an error occured...",
        noContent: "Sorry, no content was found!",
        videoAutoPlay: true,
        videoThumbnail: true,
      });

      if (gallery) {
        gallery.getGalleries();
      } else {
        mobx.init();
        gallery = mobx;
      }
    }
  }, []);

  const { formatString, intl } = useFormat(compPrefix);

  return (
    <Page>
      <Helmet
        title={formatString("title")}
        htmlAttributes={{
          lang: intl.locale,
        }}
        meta={[
          {
            name: `description`,
            content: formatString("description"),
          },
          {
            name: `keywords`,
            content: formatString("keywords"),
          },
          {
            name: `robots`,
            content: `all`,
          },
          {
            name: "theme-color",
            value: "#FF9D5A",
          },
          {
            name: "msapplication-TileColor",
            value: "#FF9D5A",
          },
          {
            name: "viewport",
            content:
              "width=device-width, user-scalable=0, initial-scale=1.0, maximum-scale=1.0, shrink-to-fit=0",
          },
          //** OG META *****************************************
          {
            property: `og:title`,
            content: formatString("title"),
          },
          {
            property: `og:description`,
            content: formatString("description"),
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:url`,
            content: formatString("socialUrl"),
          },
          {
            property: `og:image`,
            content: require("../../assets/seo/social.jpg"),
          },
          {
            property: `og:site_name`,
            content: formatString("socialSiteName"),
          },
          //** TWITTER META ************************************
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:image`,
            content: require("../../assets/seo/social.jpg"),
          },
        ]}
      >
        <script async data-cookie-consent="tracking" src="https://www.googletagmanager.com/gtag/js?id=G-3M4J337TNS"/>

        <script>{`
          function cookieConsentFunc() {
            console.log(typeof cookieconsent);
            console.log("${intl.locale}");
            if (typeof cookieconsent === "undefined") {
              setTimeout(cookieConsentFunc, 500);
            } else {
              function cookieConsent() {
                let cookieConsentLevel = getCookie('cookie_consent_level');
                if (cookieConsentLevel) {
                  let cookieConsentLevelJson = JSON.parse(getCookie('cookie_consent_level'));
                  if (cookieConsentLevelJson.tracking) {
                    var script = document.createElement("script");
                    script.dataset.cookieConsent = "tracking";
                    script.innerHTML = 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "G-3M4J337TNS");';
                    document.head.appendChild(script);
                  }
                }
              }
              cookieConsent();
            
              cookieconsent.run({"notice_banner_type":"simple","consent_type":"express","palette":"light","language":"${intl.locale}","page_load_consent_levels":["strictly-necessary"],"notice_banner_reject_button_hide":false,"preferences_center_close_button_hide":false,"page_refresh_confirmation_buttons":false,"website_name":"moover.eu"});
              
              let btn = document.getElementsByClassName("cc-nb-okagree")[0];
              if (typeof btn !== "undefined") {
                btn.addEventListener("click", function() {
                  cookieConsent();
                });
              }
            }
          }
          
          document.addEventListener("DOMContentLoaded", cookieConsentFunc());
          
          function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
          }
        `}
        </script>

        <script
          type="module"
          async
          src="https://unpkg.com/@google/model-viewer@1.12.0/dist/model-viewer.min.js"
        />
        <link
          rel="stylesheet"
          href={`${staticFile("/modulobox.min.css")}`}
          media="screen"
        />
      </Helmet>
      <Content />
    </Page>
  );
};

let forwardLimitReached = false;
let backwardLimitReached = true;
let endOfTrackTimeout: any;
let initialAction = true;

function handleRemoteAction(
  pressType: "up" | "down",
  video: HTMLVideoElement,
  direction: "forward" | "backward"
) {
  if (initialAction) {
    video.currentTime = 3;
    initialAction = false;
    video.playbackRate = 1;
  }

  const { currentTime, duration } = video;
  const videoMidPoint = duration / 2;

  if (pressType === "down") {
    const flipedTime =
      videoMidPoint > currentTime
        ? videoMidPoint - currentTime
        : duration - currentTime;

    if (direction === "forward" && currentTime > videoMidPoint) {
      video.currentTime = flipedTime;
    }

    if (direction === "backward" && currentTime < videoMidPoint) {
      video.currentTime = videoMidPoint + flipedTime;
    }

    if (backwardLimitReached && direction === "backward") return;
    if (forwardLimitReached && direction === "forward") return;

    // no need to care about backward direction since the video will stop automatically
    if (direction === "forward") {
      const timeToEnd =
        currentTime > videoMidPoint
          ? videoMidPoint - flipedTime
          : videoMidPoint - currentTime;
      clearTimeout(endOfTrackTimeout);
      endOfTrackTimeout = setTimeout(() => {
        if (direction === "forward") {
          forwardLimitReached = true;
          backwardLimitReached = false;
        }
        video.pause();
      }, timeToEnd * 1000);
    }

    const play = async function () {
      try {
        await video.play();
      } catch (e) {}
    };
    play().then();
  } else {
    clearTimeout(endOfTrackTimeout);
    forwardLimitReached =
      (currentTime < videoMidPoint
        ? videoMidPoint - currentTime
        : currentTime - videoMidPoint) < 0.2;
    backwardLimitReached = duration - currentTime < 0.2;
    video.pause();
  }
}

const Content: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>();
  const [currentSection, setCurrentSection] = useState<number | null>(null);
  const [hHeaderAnimationProgress, setHHeaderAnimationProgress] = useState(1);
  const [contactFormActive, setContactFormActive] = useState(false);
  const [controllerTarget, setControllerTarget] =
    useState<HTMLDivElement | null>(null);
  const [footerTarget, setFooterTarget] = useState<HTMLDivElement | null>(null);
  const [controllerWrapper, setControllerWrapper] =
    useState<HTMLDivElement | null>(null);
  const { isTouchDevice } = useContext(PageStateContext);

  useEffect(() => {
    const bodyStyle = document.body.style;
    bodyStyle.overflowY = contactFormActive ? "hidden" : "initial";
  }, [contactFormActive]);

  function remoteForward(pressType: "up" | "down") {
    const videoEl = videoRef.current;
    if (videoEl) {
      handleRemoteAction(pressType, videoEl, "forward");
    }
  }

  function remoteBackward(pressType: "up" | "down") {
    const videoEl = videoRef.current;
    if (videoEl) {
      handleRemoteAction(pressType, videoEl, "backward");
    }
  }

  return (
    <PageStateProvider>
      {contactFormActive && (
        <ModalStateProvider>
          <Index_ContactForm
            onCloseRequest={() => setContactFormActive(false)}
          />
        </ModalStateProvider>
      )}
      <ScrollStateProvider>
        <Index_NavBar
          navLightMode={isTouchDevice && hHeaderAnimationProgress < 1}
          navVisible={hHeaderAnimationProgress > (isTouchDevice ? 0.6 : 1)}
          setCurrentSection={(name) => setCurrentSection(name)}
        />
        <Index_CtaButton
          controls={{ backward: remoteBackward, forward: remoteForward }}
          onClick={() => setContactFormActive(true)}
          controllerTargetRef={controllerTarget}
          wrapperRef={controllerWrapper}
          footerTargetRef={footerTarget}
          leftHeroHeader={hHeaderAnimationProgress > 1.1}
        />
        <Index_HeroHeader
          updateAnimationProgress={setHHeaderAnimationProgress}
          currentSection={currentSection}
        />
      </ScrollStateProvider>
      <Index_Introduction />
      {/* <Index_TryMoover
        getVideoRef={(e) => (videoRef.current = e)}
        getWrapperRef={(e) => setControllerWrapper(e)}
        getControllerTargetRef={(e) => setControllerTarget(e)}
      /> */}
      <Index_Benefits />
      <Index_View3D currentSection={currentSection} />
      <Index_Installation />
      {/* <Index_LearnMore /> */}
      <Index_FAQ openSellerModal={() => setContactFormActive(true)} />
      <Index_Gallery />
      <Index_Support />
      {/* <Index_AlbixonTechnologyImage
        getFooterTargetRef={(e) => setFooterTarget(e)}
      /> */}
      <div ref={(e) => setFooterTarget(e)}></div>
    </PageStateProvider>
  );
};

export default StyledIndexPage;
