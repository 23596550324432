import React, { useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { cls } from "../../utils/helpers";
import { H_3 } from "../typography/heading";
import Chevron from "../../assets/icons/chevron.svg";
import { e_textTag } from "../typography/text";

interface i_accordionProps {
  title: string;
  answerTitle: string;
}

function getContentHeight(el: HTMLDivElement | null) {
  if (!el) return 0;

  return el.scrollHeight;
}

export const Accordion: React.FC<i_accordionProps> = ({
  children,
  answerTitle,
  title,
}) => {
  const [accordionOpened, setAccordionOpened] = useState(false);
  const [accordionContentHeight, setAccordionContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  function handleResize() {
    setAccordionContentHeight(getContentHeight(contentRef.current));
  }

  useEffect(() => {
    if (accordionOpened) {
      handleResize();
    }
  }, [accordionOpened]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return function () {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={cls(styles.accordion, accordionOpened && styles.opened)}>
      <div
        className={styles.head}
        onClick={() => setAccordionOpened(!accordionOpened)}
      >
        <H_3 tag={e_textTag.h4} className={styles.title}>
          {title}
        </H_3>
        <Chevron className={styles.icon} />
      </div>
      <div
        ref={contentRef}
        className={styles.content}
        style={{
          maxHeight: accordionOpened ? accordionContentHeight : 0,
        }}
      >
        <div className={styles.innerContent}>
          <p className={styles.answerTitle}>{answerTitle}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

export const AccordionWrapper: React.FC = ({ children }) => {
  return <div className={styles.accordionWrapper}>{children}</div>;
};
