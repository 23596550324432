import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState
} from "react";
import { Translate } from "../../../../../../components/translate";
import { getCaptchaKey } from "../../../../../../config";
import { useFormat } from "../../../../../../hooks/useFormat";
import {
	cls,
	getSelectedLang,
	isBrowser,
	transPrefix
} from "../../../../../../utils/helpers";
import { i_defaultComponentProps } from "../../../../../../utils/interfaces";
import { e_contactFormPages, ModalStateContext } from "../../index";
import { sendEmail } from "../../pages/contact/email";
import { t_formData, t_pageControls } from "../../types";
import { ContactForm_Button } from "../button";
import {
	ContactFormInput,
	ContactFormMessage,
	ContactFormSelect
} from "../input";
import { ContactForm_Loader } from "../loader";
import * as styles from "./index.module.scss";

const pagePrefix = transPrefix("contact-field");

interface i_contactFieldProps extends i_defaultComponentProps {
  page: t_pageControls;
  data: t_formData;
  lang?: boolean;
  b2b?: boolean;
  successPage: e_contactFormPages;
  localErrorState: [string, Dispatch<SetStateAction<string>>];
}

let submitLimiter: any = null;

export type t_clientCategory = "eshop" | "small" | "big";

export const ContactForm_ContactField: React.FC<i_contactFieldProps> = ({
  className,
  page,
  data: [data],
  lang,
  successPage,
  b2b,
  localErrorState,
}) => {
  const { formatString } = useFormat(pagePrefix);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [compnayNameOrVat, setCompnayNameOrVat] = useState("");
  const [clientCategory, setClientCategory] =
    useState<t_clientCategory>("eshop");
  const [selectedLang, setSelectedLang] = useState("");
  const [localError, setLocalError] = localErrorState;
  const [failedToSend, setFailedToSend] = useState(false);
  const [captchaResult, setCaptchaResult] = useState("");
  const { captchaLoaded } = useContext(ModalStateContext);

  useEffect(() => {
    setSelectedLang(getSelectedLang().toUpperCase());
	
    return function () {
      clearTimeout(submitLimiter);
      submitLimiter = null;
    };
  }, []);

  async function handleSubmit(e: any) {
    e.preventDefault();
    setFailedToSend(false);
    setLocalError("");
    if (!captchaResult) return;
    if (isSubmitting || submitLimiter !== null) return;
    const { contactSeller } = data;
    setIsSubmitting(true);
    if (contactSeller) {
      try {
        await sendEmail({
          captchaResult,
          message,
          contact,
          selectedLang,
          clientType: b2b ? "B2B" : "B2C",
          clientCategory,
          compnayNameOrVat,
          seller: contactSeller.id,
        });
        submitLimiter = null;
        page.next(successPage);
      } catch (e) {
        submitLimiter = setTimeout(() => {
          submitLimiter = null;
        }, 10000);
		console.error(e)
        setFailedToSend(true);
        setLocalError(formatString("formSendFailure", "contact-form_error"));
      }
      setIsSubmitting(false);
    } else {
      submitLimiter = null;
      setIsSubmitting(false);
    }
  }

  function handleCaptcha(token: string) {
    setCaptchaResult(token);
  }

  function handleCaptchaExpiration() {
    setCaptchaResult("");
  }

  useEffect(() => {
    try {
      if (captchaLoaded) {
        if (grecaptcha) {
          grecaptcha.render(document.getElementById("cpt"), {
            sitekey: getCaptchaKey().v2,
            callback: handleCaptcha,
            "expired-callback": handleCaptchaExpiration,
          });
        }
      } else {
        // error handling / waiting
      }
    } catch (e) {
      grecaptcha.reset();
    }
  }, [captchaLoaded]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={cls(styles.inputWrapper, className)}>
        {failedToSend && data.contactSeller && (
          <div className={styles.backupContactDisplay}>
            <p className={styles.backupTitle}>
              {formatString("sellerDirectContact")}
            </p>
            <a
              className={styles.backupContact}
              href={`mailto:${data.contactSeller.contact.email}`}
            >
              {data.contactSeller.contact.email}
            </a>
            {data.contactSeller.contact.phone && (
              <a
                className={styles.backupContact}
                href={`tel:${data.contactSeller.contact.phone.replace(
                  / /g,
                  ""
                )}`}
              >
                {data.contactSeller.contact.phone}
              </a>
            )}
          </div>
        )}
        <InputGroup>
          <ContactFormInput
            onChange={(value) => setContact(value)}
            className={cls(
              styles.input,
            )}
            placeholder={formatString("emailPhonePlaceholder")}
          />
        </InputGroup>

        {lang && (
          <InputGroup>
            <ContactFormSelect
              id={"lang"}
              name={"lang"}
              className={styles.select}
              onChange={(value) => setSelectedLang(value)}
              label={formatString("languageSelectLabel")}
              options={(() => {
                if (isBrowser()) {
                  const lang = getSelectedLang().toUpperCase();
                  return [lang]
                    .concat(
                      ["CS", "EN", "FR", "DE", "RU"].filter(
                        (item) => item.toUpperCase() !== lang
                      )
                    )
                    .map((item) => {
                      return {
                        label: formatString(item),
                        value: item,
                      };
                    });
                } else {
                  return [];
                }
              })()}
            />
          </InputGroup>
        )}

        {b2b && (
          <div>
            <InputGroup>
              <ContactFormSelect
                className={styles.select}
                options={[
                  {
                    label: formatString("companyCategory0"),
                    value: "eshop",
                  },
                  {
                    label: formatString("companyCategory1"),
                    value: "small",
                  },
                  {
                    label: formatString("companyCategory2"),
                    value: "big",
                  },
                ]}
                onChange={(value) => setClientCategory(value as t_clientCategory)}
                name={"Category"}
                id={"Category"}
                label={formatString("companyCategorySelectLabel")}
              />
            </InputGroup>
            <InputGroup>
              <ContactFormInput
                onChange={(value) => setCompnayNameOrVat(value)}
                className={cls(styles.input)}
                placeholder={formatString("vatPlaceholder")}
              />
            </InputGroup>
          </div>
        )}
        <InputGroup className={styles.withoutMargin}>
          <ContactFormMessage
            placeholder={formatString("messagePlaceholder")}
            onChange={(value) => setMessage(value)}
          />
        </InputGroup>

        {captchaLoaded ? (
          <div className={styles.cpt} id={"cpt"} />
        ) : (
          <div className={styles.loader}>
            <ContactForm_Loader small />
          </div>
        )}

        <ContactForm_Button
          submit
          onClick={handleSubmit}
          biggerFont={true}
          thickerFont={true}
          disabled={
            !captchaResult ||
            isSubmitting ||
            (!compnayNameOrVat && b2b)
          }
          loader={<ContactForm_Loader small white noMargin />}
          loaderActive={isSubmitting}
        >
          <Translate id={pagePrefix("send")} />
        </ContactForm_Button>
      </div>
    </form>
  );
};

const InputGroup: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <div className={cls(styles.inputGroup, className)}>{children}</div>;
};
