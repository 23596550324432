import React, { useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { Container } from "../../../../components/container";
import { cls, transPrefix } from "../../../../utils/helpers";
import { ContactForm_AddressPage } from "./pages/address";
import { ContactForm_SellerPage } from "./pages/seller";
import { ContactForm_AllSellersPage } from "./pages/all-sellers";
import { ContactForm_LoaderPage } from "./pages/loader";
import { e_contactVariant, t_contactFormSharedData } from "./types";
import { i_mapsApi, MapsApi } from "./maps";
import { makeCircle } from "../../../../utils/react";
import { DATA_SELLERS, DATA_SELLER_INDEXES } from "./sellers";
import { ContactForm_Contact } from "./pages/contact";
import { ContactForm_SuccessPage } from "./pages/success";
import { useFormat } from "../../../../hooks/useFormat";
import ButtonCornerVector from "../../../../assets/icons/button-corner.svg";
import { Button } from "../../../../components/button";
import { Translate } from "../../../../components/translate";
import { loadScript } from "../../../../utils/resources";

interface i_contactFormProps {
  onCloseRequest: () => void;
}

export enum e_contactFormPages {
  ADDRESS,
  LOADER,
  FIND_SELLER,
  ALL_SELLERS,

  CONTACT_SELLER,
  CONTACT_ALBIXON_B2C,
  CONTACT_ALBIXON_B2B,
  NO_SELLER,

  SUCCESS_SELLER_B2C,
  SUCCESS_ALBIXON_B2B,
  SUCCESS_ABZO,
  SUCCESS_NO_SELLER,
}

export const ModalStateContext = React.createContext<{
  api: null | i_mapsApi;
  error: string;
  setError: (error: string) => void;
  captchaLoaded: boolean | null;
}>({
  api: null,
  error: "",
  setError: (error) => {},
  captchaLoaded: false,
});

const rootPrefix = transPrefix("contact-form");

let captchaAlreadyLoaded = false;

export const ModalStateProvider: React.FC = ({ children }) => {
  const [api, setApi] = useState<i_mapsApi | null>(null);
  const [error, setError] = useState<string>("");
  //null means error
  const [captchaLoaded, setCaptchaLoaded] = useState<boolean | null>(false);
  const { formatString } = useFormat(rootPrefix);

  useEffect(() => {
    const handler = async () => {
      try {
        const api = await MapsApi.initializeMaps();
        setApi(api);
        setError("");
      } catch (e) {
        setError(formatString("error_googleApi"));
      }
    };
    handler();

    window.captchaLoaded = () => {
      setCaptchaLoaded(true);
      captchaAlreadyLoaded = true;
    };

    loadScript(
      {
        id: "captcha",
        src: "https://www.google.com/recaptcha/api.js?onload=captchaLoaded&render=explicit",
        async: true,
      },
      () => {},
      () => setCaptchaLoaded(null)
    );

    if (captchaAlreadyLoaded) setCaptchaLoaded(true);
  }, []);

  return (
    <ModalStateContext.Provider
      value={{
        api,
        error,
        setError,
        captchaLoaded,
      }}
    >
      {children}
    </ModalStateContext.Provider>
  );
};

const compPrefix = transPrefix("contact-form");

const defaultData = {
    selectedLocation: null,
    allSellers: DATA_SELLERS.filter((seller) => !seller.internal),
    pickedSeller: null,
    sellersInCountry: [],
    contactSeller: null,
  }

export const Index_ContactForm: React.FC<i_contactFormProps> = ({
  onCloseRequest,
}) => {
  const [currentPage, setCurrentPage] = useState(e_contactFormPages.ADDRESS);
  const [formData, setFormData] = useState<t_contactFormSharedData>(defaultData);

  const pageHistory = useRef<number[]>([]);

  const back = () => {
    const history = pageHistory.current;

    const returnTo = history.length
      ? history.pop()
      : e_contactFormPages.ADDRESS;

    if (returnTo === e_contactFormPages.ADDRESS) history.length = 0;
    setCurrentPage(returnTo as number);
  };

  const next = (page: e_contactFormPages, excludeHistory: boolean = false) => {
    if (!excludeHistory) {
      pageHistory.current.push(currentPage);
    }
    setCurrentPage(page);
  };

  const close = () => {
	setFormData(defaultData);
	onCloseRequest();
  }

  const pageControls = {
    back,
    next,
    close,
  };

  return (
    <div className={styles.modalWrapper} onClick={onCloseRequest}>
      <Container
        className={cls(styles.modalContent, styles.flexColumn)}
        onClick={(e) => e.stopPropagation()}
      >
        {makeCircle(300, 120, cls(styles.bgCircle, styles.bgCircle__big))}
        {makeCircle(120, 0, cls(styles.bgCircle, styles.bgCircle__small))}
        {(() => {
          switch (currentPage) {
            case e_contactFormPages.ADDRESS:
              return (
                <ContactForm_AddressPage
                  page={pageControls}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.LOADER:
              return (
                <ContactForm_LoaderPage
                  page={pageControls}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.FIND_SELLER:
              return (
                <ContactForm_SellerPage
                  page={pageControls}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.ALL_SELLERS:
              return (
                <ContactForm_AllSellersPage
                  page={pageControls}
                  data={[formData, setFormData]}
                />
              );

            // CONTACT PAGE
            case e_contactFormPages.CONTACT_SELLER:
              return (
                <ContactForm_Contact
                  page={pageControls}
                  variant={e_contactVariant.SELLER_B2C}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.CONTACT_ALBIXON_B2C:
              return (
                <ContactForm_Contact
                  page={pageControls}
                  variant={e_contactVariant.ALBIXON_ZO}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.CONTACT_ALBIXON_B2B:
              return (
                <ContactForm_Contact
                  page={pageControls}
                  variant={e_contactVariant.ALBIXON_B2B}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.NO_SELLER:
              return (
                <ContactForm_Contact
                  page={pageControls}
                  variant={e_contactVariant.NO_SELLER}
                  data={[formData, setFormData]}
                />
              );

            // SUCCESS PAGE
            case e_contactFormPages.SUCCESS_SELLER_B2C:
              return (
                <ContactForm_SuccessPage
                  variant={e_contactVariant.SELLER_B2C}
                  page={pageControls}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.SUCCESS_ALBIXON_B2B:
              return (
                <ContactForm_SuccessPage
                  page={pageControls}
                  variant={e_contactVariant.ALBIXON_B2B}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.SUCCESS_ABZO:
              return (
                <ContactForm_SuccessPage
                  page={pageControls}
                  variant={e_contactVariant.ALBIXON_ZO}
                  data={[formData, setFormData]}
                />
              );
            case e_contactFormPages.SUCCESS_NO_SELLER:
              return (
                <ContactForm_SuccessPage
                  page={pageControls}
                  variant={e_contactVariant.NO_SELLER}
                  data={[formData, setFormData]}
                />
              );
          }
        })()}
      </Container>
      {currentPage === e_contactFormPages.ADDRESS && (
        <div className={styles.modalB2BOverlay}>
          <Button
            className={styles.bottomButton}
            onClick={(e) => {
              e.stopPropagation();
              setFormData({
                ...formData,
                contactSeller: DATA_SELLERS[DATA_SELLER_INDEXES.b2b],
              });
              next(e_contactFormPages.CONTACT_ALBIXON_B2B);
            }}
          >
            <Translate id={compPrefix("b2b")} />
            <ButtonCornerVector className={styles.bottomButton__cornerLeft} />
            <ButtonCornerVector className={styles.bottomButton__cornerRight} />
          </Button>
        </div>
      )}
    </div>
  );
};
