//@refactored
import React from "react";
import { H_2, H_4 } from "../../../../components/typography/heading";
import {
  e_fontWeight,
  e_textAlign,
  e_textTag,
} from "../../../../components/typography/text";
import { Container } from "../../../../components/container";
import { Accordion, AccordionWrapper } from "../../../../components/accordion";
import { Translate } from "../../../../components/translate";
import { useFormat } from "../../../../hooks/useFormat";
import { navPrefix, transPrefix } from "../../../../utils/helpers";
import { Button } from "../../../../components/button";
import IconLongArrow from "../../../../assets/icons/arrow-long.svg";

const compPrefix = transPrefix("faq");

export const Index_FAQ: React.FC<{ openSellerModal: () => void }> = ({
  openSellerModal,
}) => {
  const { formatString } = useFormat(compPrefix);
  return (
    <section id={formatString("link4Anchor", navPrefix)}>
      <Container pt mb>
        <H_4 tag={e_textTag.p} textAlign={e_textAlign.CENTER}>
          <Translate id={compPrefix("h4")} />
        </H_4>
        <H_2 tag={e_textTag.h2} textAlign={e_textAlign.CENTER}>
          <Translate id={compPrefix("h1")} />
        </H_2>
      </Container>
      <Container mb>
        <AccordionWrapper>
          {Array.from(new Array(7)).map((faqItem, index) => (
            <Accordion
              key={index}
              title={formatString(`q${index}Title`)}
              answerTitle={formatString(`a${index}Title`)}
            >
              <Translate id={compPrefix(`a${index}Body`)} />
              {index === 6 && (
                <Button
                  style={{ marginTop: 20 }}
                  textVariant
                  fontWeight={e_fontWeight.w300}
                  textAlign={e_textAlign.LEFT}
                  uppercase={false}
                  onClick={openSellerModal}
                  icon={<IconLongArrow />}
                  flipIcon
                >
                  {formatString("sellerModal")}
                </Button>
              )}
            </Accordion>
          ))}
        </AccordionWrapper>
      </Container>
    </section>
  );
};
