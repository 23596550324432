import React from "react";
import * as styles from "./index.module.scss";
import { cls } from "../../../../../../utils/helpers";

export const ContactForm_Loader: React.FC<{
  small?: boolean;
  noMargin?: boolean;
  white?: boolean;
}> = ({ small = false, noMargin, white }) => {
  return (
    <div
      className={cls(
        styles.loader,
        small && styles.small,
        noMargin && styles.noMargin,
        white && styles.white
      )}
    >
      <div />
      <div />
      <div />
    </div>
  );
};
