import { useEffect, useRef } from "react";
import { setClassIf } from "../utils/helpers";
import React from "react";

export function useManagedClass<T extends HTMLElement>(
  condition: boolean,
  className: string
) {
  const elRef = useRef<T>(null);

  useEffect(() => {
    if (elRef.current) {
      setClassIf(condition, elRef.current, className);
    }
  }, [elRef, condition, className]);

  return { ref: elRef };
}
