//@refactored
import React, { useState } from "react";
import * as styles from "./index.module.scss";
import { H_2, H_4 } from "../../../../components/typography/heading";
import { e_textAlign, e_textTag } from "../../../../components/typography/text";
import { Container } from "../../../../components/container";
import {
  e_supportLinkType,
  Panel,
  PanelWrapper,
} from "../../../../components/panel";
import { Button } from "../../../../components/button";
import { Translate } from "../../../../components/translate";
import { useFormat } from "../../../../hooks/useFormat";
import { navPrefix } from "../../../../utils/helpers";
import { compPrefix, content } from "./data";
import DocIcon from "../../../../assets/icons/document.svg";
import LinkIcon from "../../../../assets/icons/link.svg";

export const Index_Support: React.FC = () => {
  const { formatString } = useFormat(compPrefix);
  const [showMore, setShowMore] = useState(false);

  return (
    <section id={formatString("link6Anchor", navPrefix)}>
      <Container mb pt>
        <H_4 tag={e_textTag.p} textAlign={e_textAlign.CENTER}>
          <Translate id={compPrefix("h4")} />
        </H_4>
        <H_2 tag={e_textTag.h2} textAlign={e_textAlign.CENTER}>
          <Translate id={compPrefix("h1")} />
        </H_2>
      </Container>
      <Container mb2>
        <PanelWrapper>
          {content
            .slice(0, showMore ? content.length : 4)
            .map((item, index) => (
              <Panel
                key={index}
                handler={formatString(item.handler)}
                className={styles.panel}
                title={formatString(`panel${index}Title`)}
                heading={<Translate id={compPrefix(`panel${index}Heading`)} />}
                icon={
                  item.type === e_supportLinkType.Download ? (
                    <DocIcon />
                  ) : (
                    <LinkIcon />
                  )
                }
                type={item.type}
              />
            ))}
        </PanelWrapper>
        {content.length > 4 && !showMore && (
          <Button
            className={styles.showMoreBtn}
            onClick={() => setShowMore(true)}
            textVariant
          >
            <Translate id={compPrefix(`showMoreBtn`)} />
          </Button>
        )}
      </Container>
    </section>
  );
};
