import React, { useContext, useEffect, useRef, useState } from "react";
import * as styles from "./index.module.scss";
import { Button } from "../../../../components/button";
import {
  PageStateContext,
  ScrollContext,
} from "../../../../components/page-state-provider";
import { Translate } from "../../../../components/translate";
import { clamp, cls, isBrowser, transPrefix } from "../../../../utils/helpers";

export interface i_remoteControls {
  forward: (pressType: "up" | "down") => void;
  backward: (pressType: "up" | "down") => void;
}

interface i_ctaButtonProps {
  controllerTargetRef: HTMLDivElement | null;
  footerTargetRef: HTMLDivElement | null;
  wrapperRef: HTMLDivElement | null;
  onClick: () => void;
  controls: i_remoteControls;
  leftHeroHeader: boolean;
}

const prefix = transPrefix("cta-button");

function checkDevice() {
  // assume that devices with higher ratio are borderless
  if (!isBrowser()) return false;
  const ratio = window.screen.width / window.screen.height;
  return ratio < 0.5;
}

export const Index_CtaButton: React.FC<i_ctaButtonProps> = ({
  footerTargetRef,
  controllerTargetRef,
  wrapperRef,
  onClick,
  controls,
  leftHeroHeader,
}) => {
  const { scrollY } = useContext(ScrollContext);
  const { isTouchDevice } = useContext(PageStateContext);
  const [isAtFooter, setIsAtFooter] = useState(false);
  const [controllerProgress, setControllerProgress] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>();
  const isFrameless = checkDevice();

  //TODO: UPDATED WITH INTERACTIVE VIDEO REMOVAL
  useEffect(() => {
    if (footerTargetRef) {
      //Current footer target position
      const cftp =
        footerTargetRef.getBoundingClientRect().bottom - window.innerHeight;

      setIsAtFooter(cftp < 0);
    }
  }, [scrollY]);

  //   useEffect(() => {
  //     if (footerTargetRef && controllerTargetRef && wrapperRef) {
  //       //Current footer target position
  //       const cftp =
  //         footerTargetRef.getBoundingClientRect().bottom - window.innerHeight;

  //       setIsAtFooter(cftp < 0);

  //       const wrapHeight = wrapperRef.clientHeight;

  //       // dsp - deviceStartPositive
  //       const dspOffset = isTouchDevice ? -200 : 0;
  //       // den - deviceEndNegative
  //       const denOffset = isTouchDevice ? -wrapHeight / 2 : 0;

  //       // el - element
  //       const elStart = wrapperRef.offsetTop;
  //       const elEnd = elStart + wrapHeight;
  //       const elRange = elEnd - elStart;
  //       // p - positive
  //       const pStart = elStart + dspOffset;
  //       const pEnd = pStart + elRange / 4;
  //       // n - negative
  //       const nStart = pEnd + elRange / 4;
  //       const nEnd = elEnd + denOffset;
  //       // abs - absolute
  //       const absRange = nEnd - pStart;
  //       const absProgress = clamp(0, absRange, scrollY - pStart) / absRange;
  //       // r - relative
  //       let rRange, rProgress;
  //       if (scrollY < nStart) {
  //         rRange = pEnd - pStart;
  //         rProgress = clamp(0, rRange, scrollY - pStart) / rRange;
  //       } else {
  //         rRange = nEnd - nStart;
  //         rProgress = clamp(0, rRange, scrollY - nStart) / rRange;
  //       }

  //       setControllerProgress(scrollY < nStart ? rProgress : 1 - rProgress);
  //     }
  //   }, [scrollY]);

  //   function updateButton() {
  //     const btn = buttonRef.current;

  //     if (btn) {
  //       const { style } = btn;
  //       if (!isTouchDevice) {
  //         // style.pointerEvents = controllerProgress > 0 ? "none" : "all";
  //         style.transform = `translate3d(0,${
  //           200 * clamp(0, 1, controllerProgress)
  //         }px,0) scale3d(${1 - clamp(0, 0.01, controllerProgress)},1,1)`;
  //       } else {
  //         if (controllerProgress > 0.3) {
  //           btn.classList.add(styles.shrunk);
  //         } else {
  //           btn.classList.remove(styles.shrunk);
  //         }
  //       }
  //     }
  //   }

  useEffect(() => {
    // updateButton();
  }, [controllerProgress]);

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={cls(
            styles.controllerWrapper,
            isFrameless && styles.frameless,
            leftHeroHeader && styles.lowered, //TODO: UPDATED WITH INTERACTIVE VIDEO REMOVAL
            styles.lowered,
            isTouchDevice && controllerProgress > 0 && styles.mobileActive,
            isAtFooter && styles.atFooter
          )}
        >
          <Button
            className={styles.button}
            onClick={onClick}
            getRef={(e) => (buttonRef.current = e)}
          >
            <Translate id={prefix("text")} />
          </Button>
          {/*<Remote*/}
          {/*  controls={controls}*/}
          {/*  progress={controllerProgress}*/}
          {/*  touchDevice={isTouchDevice}*/}
          {/*/>*/}
          <RemoteNew
            controls={controls}
            progress={controllerProgress}
            touchDevice={isTouchDevice}
          />
        </div>
      </div>
    </>
  );
};

const RemoteNew: React.FC<{
  controls: i_remoteControls;
  progress: number;
  touchDevice: boolean;
}> = ({ controls, progress, touchDevice }) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (!touchDevice) {
        ref.current.style.transform = `translate3d(0, ${
          100 - 55 * progress
        }%, 0)`;
      }
    }
  }, [ref, touchDevice, progress]);

  const [forwardState, setForwardState] = useState(false);
  const [backwardState, setBackwardState] = useState(false);

  return (
    <svg
      width="299"
      height="540"
      viewBox="0 0 299 540"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.controller}
      ref={ref}
    >
      <g filter="url(#filter0_i)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M270.521 539.843C286.091 539.843 298.713 527.221 298.713 511.651L298.713 28.1918C298.713 12.6219 286.091 2.36497e-05 270.521 2.11851e-05L28.1919 0C12.622 -1.36116e-06 4.36265e-05 12.6219 4.22654e-05 28.1918L0 511.651C-2.46461e-06 527.221 12.6219 539.843 28.1918 539.843H270.521ZM123.564 472.663C120.251 472.663 117.566 475.348 117.566 478.661V494.256C117.566 497.569 120.251 500.255 123.564 500.255H175.149C178.462 500.255 181.147 497.569 181.147 494.256V478.661C181.147 475.348 178.462 472.663 175.149 472.663H123.564Z"
          fill="#404040"
        />
        <path
          d="M164.154 337.788C162.944 337.788 161.759 337.893 160.604 338.085C166.154 343.458 169.614 350.978 169.614 359.295C169.614 367.614 166.152 375.136 160.6 380.509C161.756 380.702 162.943 380.807 164.154 380.807C176.034 380.807 185.664 371.177 185.664 359.297C185.664 347.418 176.034 337.788 164.154 337.788Z"
          fill="#FAFAFA"
        />
        <path
          d="M159.042 359.295C159.042 352.464 155.41 346.467 149.977 343.127C145.484 347.07 142.644 352.85 142.644 359.297C142.644 365.743 145.483 371.523 149.974 375.465C155.409 372.126 159.042 366.128 159.042 359.295Z"
          fill="#FAFAFA"
        />
        <path
          d="M140.078 380.805C128.199 380.805 118.569 371.175 118.569 359.295C118.569 347.416 128.199 337.786 140.078 337.786C151.958 337.786 161.588 347.416 161.588 359.295C161.588 371.175 151.958 380.805 140.078 380.805ZM140.078 332.303C125.171 332.303 113.086 344.388 113.086 359.295C113.086 374.202 125.171 386.288 140.078 386.288C154.986 386.288 167.07 374.202 167.07 359.295C167.07 344.388 154.986 332.303 140.078 332.303Z"
          fill="#FAFAFA"
        />
        <g
          filter="url(#filter1_i)"
          onPointerDown={() => {
            controls.backward("down");
            setBackwardState(true);
          }}
          onPointerUp={() => {
            controls.backward("up");
            setBackwardState(false);
          }}
          onPointerLeave={() => {
            controls.backward("up");
            setBackwardState(false);
          }}
          className={cls(
            styles.remoteBackward,
            backwardState && styles.pressed
          )}
        >
          <rect
            x="113.367"
            y="173.949"
            width="71.9791"
            height="71.9791"
            rx="11.9965"
            fill="#F8F8F8"
          />
        </g>
        <g
          filter="url(#filter2_i)"
          onPointerDown={() => {
            controls.forward("down");
            setForwardState(true);
          }}
          onPointerUp={() => {
            controls.forward("up");
            setForwardState(false);
          }}
          onPointerLeave={() => {
            controls.forward("up");
            setForwardState(false);
          }}
          className={cls(styles.remoteForward, forwardState && styles.pressed)}
        >
          <rect
            x="184.979"
            y="133.979"
            width="71.9791"
            height="71.9791"
            rx="11.9965"
            transform="rotate(180 184.979 133.979)"
            fill="#F8F8F8"
          />
        </g>
        <g filter="url(#filter3_i)" className={styles.remoteBackwardIcon}>
          <rect
            x="128.941"
            y="223.205"
            width="3.59895"
            height="40.7882"
            rx="1.79948"
            transform="rotate(-90 128.941 223.205)"
            fill="#A9A9A9"
          />
        </g>
        <g filter="url(#filter4_i)" className={styles.remoteBackwardIcon}>
          <path
            d="M149.335 214.788L167.499 198.472L131.171 198.472L149.335 214.788Z"
            stroke="#A9A9A9"
            strokeWidth="3.59895"
          />
        </g>
        <g filter="url(#filter5_i)" className={styles.remoteForwardIcon}>
          <path
            d="M149.14 85.3524L130.975 101.668L167.304 101.668L149.14 85.3524Z"
            stroke="#A9A9A9"
            strokeWidth="3.59895"
          />
        </g>
        <g filter="url(#filter6_i)" className={styles.remoteForwardIcon}>
          <rect
            x="169.533"
            y="109.468"
            width="3.59895"
            height="40.7882"
            rx="1.79948"
            transform="rotate(90 169.533 109.468)"
            fill="#A9A9A9"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="298.713"
          height="539.843"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.69835" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.766667 0 0 0 0 0.766667 0 0 0 0 0.766667 0 0 0 0.4 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter1_i"
          x="113.367"
          y="173.949"
          width="71.9791"
          height="71.9791"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.99913" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter2_i"
          x="113"
          y="62"
          width="71.9791"
          height="71.9791"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.99913" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter3_i"
          x="128.941"
          y="219.606"
          width="40.7882"
          height="4.19878"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter4_i"
          x="129.368"
          y="196.673"
          width="39.9345"
          height="20.5143"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter5_i"
          x="129.172"
          y="83.553"
          width="39.9345"
          height="20.5143"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter6_i"
          x="128.745"
          y="109.468"
          width="40.7882"
          height="4.19878"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
};

const Remote: React.FC<{
  controls: i_remoteControls;
  progress: number;
  touchDevice: boolean;
}> = ({ controls, progress, touchDevice }) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (ref.current) {
      if (!touchDevice) {
        ref.current.style.transform = `translate3d(0, ${
          100 - 50 * progress
        }%, 0)`;
      }
    }
  }, [ref, touchDevice, progress]);

  return (
    <svg
      width="299"
      height="540"
      viewBox="0 0 299 540"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.controller}
      ref={ref}
    >
      <g filter="url(#filter0_i)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M270.521 539.843C286.091 539.843 298.713 527.221 298.713 511.651L298.713 28.1918C298.713 12.6219 286.091 2.36497e-05 270.521 2.11851e-05L28.1919 0C12.622 -1.36116e-06 4.36265e-05 12.6219 4.22654e-05 28.1918L0 511.651C-2.46461e-06 527.221 12.6219 539.843 28.1918 539.843H270.521ZM123.564 472.663C120.251 472.663 117.566 475.348 117.566 478.661V494.256C117.566 497.569 120.251 500.255 123.564 500.255H175.149C178.462 500.255 181.147 497.569 181.147 494.256V478.661C181.147 475.348 178.462 472.663 175.149 472.663H123.564Z"
          fill="#404040"
        />
        <g
          filter="url(#filter1_i)"
          onPointerDown={() => controls.forward("down")}
          onPointerUp={() => controls.forward("up")}
          onPointerLeave={() => controls.forward("up")}
          className={styles.remoteForward}
        >
          <rect
            x="113.367"
            y="62.3818"
            width="71.9791"
            height="71.9791"
            rx="11.9965"
            fill="#FF9D5A"
          />
        </g>
        <path
          d="M164.154 337.788C162.944 337.788 161.759 337.893 160.604 338.085C166.154 343.458 169.614 350.978 169.614 359.295C169.614 367.614 166.152 375.136 160.6 380.509C161.756 380.703 162.943 380.807 164.154 380.807C176.034 380.807 185.664 371.177 185.664 359.297C185.664 347.418 176.034 337.788 164.154 337.788Z"
          fill="#FAFAFA"
        />
        <path
          d="M159.042 359.295C159.042 352.464 155.41 346.467 149.977 343.128C145.484 347.07 142.644 352.85 142.644 359.298C142.644 365.743 145.483 371.523 149.974 375.466C155.409 372.127 159.042 366.128 159.042 359.295Z"
          fill="#FAFAFA"
        />
        <path
          d="M140.078 380.805C128.199 380.805 118.569 371.176 118.569 359.295C118.569 347.416 128.199 337.786 140.078 337.786C151.958 337.786 161.588 347.416 161.588 359.295C161.588 371.176 151.958 380.805 140.078 380.805ZM140.078 332.304C125.171 332.304 113.086 344.388 113.086 359.295C113.086 374.203 125.171 386.288 140.078 386.288C154.986 386.288 167.07 374.203 167.07 359.295C167.07 344.388 154.986 332.304 140.078 332.304Z"
          fill="#FAFAFA"
        />
        <g
          filter="url(#filter2_i)"
          onPointerDown={() => controls.backward("down")}
          onPointerUp={() => controls.backward("up")}
          onPointerLeave={() => controls.backward("up")}
          className={styles.remoteBackward}
        >
          <rect
            x="113.367"
            y="173.949"
            width="71.9791"
            height="71.9791"
            rx="11.9965"
            fill="#F8F8F8"
          />
        </g>
        <g filter="url(#filter3_i)" className={styles.remoteBackwardIcon}>
          <rect
            x="128.941"
            y="223.205"
            width="3.59895"
            height="40.7882"
            rx="1.79948"
            transform="rotate(-90 128.941 223.205)"
            fill="#A9A9A9"
          />
        </g>
        <g filter="url(#filter4_i)" className={styles.remoteBackwardIcon}>
          <path
            d="M149.335 214.788L167.499 198.472L131.171 198.472L149.335 214.788Z"
            stroke="#A9A9A9"
            strokeWidth="3.59895"
          />
        </g>
        <g filter="url(#filter5_i)">
          <rect
            x="129.541"
            y="112.837"
            width="3.59895"
            height="39.5885"
            rx="1.79948"
            transform="rotate(-90 129.541 112.837)"
            fill="white"
          />
        </g>
        <g filter="url(#filter6_i)">
          <path
            d="M149.335 85.1249L167.499 101.44H131.171L149.335 85.1249Z"
            stroke="white"
            strokeWidth="3.59895"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="298.713"
          height="539.843"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5.69835" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.766667 0 0 0 0 0.766667 0 0 0 0 0.766667 0 0 0 0.4 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter1_i"
          x="113.367"
          y="62.3818"
          width="71.9791"
          height="71.9791"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.99913" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter2_i"
          x="113.367"
          y="173.949"
          width="71.9791"
          height="71.9791"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.99913" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 0 0.441667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter3_i"
          x="128.941"
          y="219.606"
          width="40.7882"
          height="4.19878"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter4_i"
          x="129.368"
          y="196.673"
          width="39.9345"
          height="20.5143"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.599826" />
          <feGaussianBlur stdDeviation="0.899739" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter5_i"
          x="129.541"
          y="109.238"
          width="39.5885"
          height="3.59895"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.599826" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.375779 0 0 0 0 0.0885986 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter6_i"
          x="129.368"
          y="83.3254"
          width="39.9345"
          height="19.9145"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.599826" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.375779 0 0 0 0 0.0885986 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
};
