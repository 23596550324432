// extracted by mini-css-extract-plugin
export var anchor = "g_G";
export var container = "g_g";
export var content = "g_f";
export var contentWrapper = "g_F";
export var controls = "g_M";
export var coverBar = "g_Q";
export var coverBar1 = "g_T";
export var coverBar2 = "g_R";
export var coverBar3 = "g_S";
export var handleArrow = "g_X";
export var hidden = "g_J";
export var langBar = "g_H";
export var logo = "g_L";
export var mobile = "g_P";
export var notTouch = "g_Y";
export var playBtn = "g_N";
export var video = "g_V";
export var videoOverlay = "g_W";
export var videoWrapper = "g_K";
export var wrapper = "g_D";