import React, { useEffect, useState } from "react";
import * as styles from "./index.module.scss";
import { cls } from "../../utils/helpers";

interface i_animatedMountProps {
  mounted: boolean;
  onActionEnd: () => void;
  wrapperClass?: string;
  mountClass?: string;
  unmountClass?: string;
}

export const AnimatedMount: React.FC<i_animatedMountProps> = ({
  mounted,
  mountClass,
  unmountClass,
  onActionEnd,
  wrapperClass,
  children,
}) => {
  const [shouldRender, setShouldRender] = useState(mounted);

  useEffect(() => {
    if (mounted) setShouldRender(true);
  }, [mounted]);

  const onAnimationEnd = () => {
    if (!mounted) setShouldRender(false);
    onActionEnd();
  };

  const _mountClass = mountClass || styles.mountClass;
  const _unmountClass = unmountClass || styles.unmountClass;

  return shouldRender ? (
    <div
      className={cls(mounted ? _mountClass : _unmountClass, wrapperClass)}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
};
