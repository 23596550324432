import { graphql, useStaticQuery } from "gatsby";
import { transPrefix } from "../../../../utils/helpers";

export const compPrefix = transPrefix("gallery");

export enum e_slideType {
  video,
  img,
}

export interface i_slide {
  src?: string;
  external?: boolean;
  name: string;
  type: e_slideType;
}

export const slides: i_slide[] = [
  { type: e_slideType.img, name: "0" },
  { type: e_slideType.img, name: "1" },
  { type: e_slideType.img, name: "2" },
  { type: e_slideType.img, name: "3" },
  { type: e_slideType.img, name: "4" },
  { type: e_slideType.img, name: "5" },
  { type: e_slideType.img, name: "6" },
  { type: e_slideType.img, name: "8" },
  { type: e_slideType.img, name: "10" },
  { type: e_slideType.img, name: "11" },
  { type: e_slideType.img, name: "12" },
  { type: e_slideType.img, name: "13" },
  { type: e_slideType.img, name: "14" },
  { type: e_slideType.img, name: "15" },
  { type: e_slideType.img, name: "16" },
  { type: e_slideType.img, name: "17" },
];

const SLIDES_PER_ROW = 3;
const MAX_ROWS = 3;

export function getSlideCountForRow(rowIndex: number) {
  const numberOfRows = getNumberOfRows();

  const overflowingSlides = slides.length - SLIDES_PER_ROW * numberOfRows;
  const dividedOverflow = overflowingSlides / numberOfRows;
  const extraSlidesForRow =
    rowIndex !== numberOfRows - 1
      ? Math.ceil(dividedOverflow)
      : Math.floor(dividedOverflow);

  return SLIDES_PER_ROW + extraSlidesForRow;
}

export function getStartingSlideForRow(rowIndex: number) {
  let index = 0;
  Array.from(new Array(rowIndex)).forEach(
    (v, i) => (index += getSlideCountForRow(i))
  );
  return index;
}

export function getSlideIndex(rowIndex: number, slideIndex: number) {
  return getStartingSlideForRow(rowIndex) + slideIndex;
}

export function getNumberOfRows() {
  return Math.min(Math.floor(slides.length / SLIDES_PER_ROW), MAX_ROWS);
}

export function useImageQueryData() {
  return useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/gallery/" } }) {
        edges {
          node {
            base
            relativePath
            childImageSharp {
              fluid(
                quality: 100
                maxWidth: 2000
                background: "rgba(234,234,234,1)"
                base64Width: 42
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
}
