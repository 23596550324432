//@refactored
import React, { useContext, useEffect, useState } from "react";
import * as styles from "./index.module.scss";
import { Container } from "../../../../components/container";
import { e_textTag, TextBig } from "../../../../components/typography/text";
import { makeCircle } from "../../../../utils/react";
import { Translate } from "../../../../components/translate";
import { cls, transPrefix } from "../../../../utils/helpers";
import Circle from "../../../../assets/icons/circle-intro.svg";
import HandleArrow from "../../../../assets/arrow-handle-new.svg";
import ArrowIcon from "../../../../assets/icons/arrow.svg";
import { PageStateContext } from "../../../../components/page-state-provider";
import { staticFile } from "../../../../config";
import { H_1, H_4 } from "../../../../components/typography/heading";

const compPrefix = transPrefix("introduction");

export const Index_Introduction: React.FC = () => {
  const [deviceClass, setDeviceClass] = useState("");
  const { isTouchDevice, viewportHeight } = useContext(PageStateContext);

  useEffect(() => {
    if (!isTouchDevice && viewportHeight > 500) {
      setDeviceClass(styles.notMobile);
    } else {
      setDeviceClass("");
    }
  }, [viewportHeight]);

  return (
    <div className={cls(styles.wrapper, deviceClass)}>
      <HandleArrow className={styles.handleArrow} />
      <div className={styles.content}>
        {makeCircle(200, 0, styles.circleFilled)}
        <Circle className={styles.circleOutline} />
        <Container className={styles.container}>
          <H_4 tag={e_textTag.p}>
            <Translate id={compPrefix(`eyeBrow`)} />
          </H_4>
          <H_1 className={styles.h1}>
            <Translate id={compPrefix(`heading`)} />
          </H_1>
          <TextBig className={styles.text}>
            <Translate id={compPrefix(`text0`)} />
          </TextBig>
          <TextBig className={styles.text}>
            <Translate id={compPrefix(`text1`)} />
          </TextBig>
          <TextBig className={styles.text}>
            <Translate id={compPrefix(`text2`)} />
          </TextBig>
          <div
            className={cls(styles.action, "mobx")}
            data-src={staticFile("/moover-full.mp4")}
            data-rel="preview"
          >
            <TextBig>
              <Translate id={compPrefix("playVideo")} />
            </TextBig>
            <span className={styles.actionIcon}>
              <ArrowIcon />
            </span>
          </div>
        </Container>
      </div>
    </div>
  );
};
