import React from "react";
import * as styles from "./index.module.scss";
import { PageWrapper } from "../../components/page-wrapper";
import {
  e_contactVariant,
  i_contactFormPage,
  t_contactFormSharedData,
} from "../../types";
import {
  getVariantPostFix,
  transPrefix,
} from "../../../../../../utils/helpers";
import { e_textAlign } from "../../../../../../components/typography/text";
import { H_3 } from "../../../../../../components/typography/heading";
import { ContactForm_Button } from "../../components/button";
import { Translate } from "../../../../../../components/translate";
import { useFormat } from "../../../../../../hooks/useFormat";

const pagePrefix = transPrefix("contact-form_success");

interface i_contactFormSuccessPage extends i_contactFormPage {
  variant: e_contactVariant;
}

function generateSuccessMessage(
  content: string,
  { contactSeller }: t_contactFormSharedData
) {
  const parts = content.split("<>");

  if (parts.length > 1) {
	const [start, rest] = parts
    return (
      <>
        {start}
        {contactSeller?.name}
        {rest}
      </>
    );
  }

  return content;
}

const contentValues = {
  B2B: generateSuccessMessage,
  ABZO: generateSuccessMessage,
  NoSeller: generateSuccessMessage,
  SellerB2C: generateSuccessMessage,
};

function getContentValues(
  variant: e_contactVariant,
  data: t_contactFormSharedData,
  content: string
) {
  switch (variant) {
    case e_contactVariant.ALBIXON_B2B:
      return contentValues.B2B(content, data);
    case e_contactVariant.ALBIXON_ZO:
      return contentValues.ABZO(content, data);
    case e_contactVariant.NO_SELLER:
      return contentValues.NoSeller(content, data);
    case e_contactVariant.SELLER_B2C:
      return contentValues.SellerB2C(content, data);
    default:
      return <></>;
  }
}

export const ContactForm_SuccessPage: React.FC<i_contactFormSuccessPage> = ({
  page,
  data: [data],
  variant,
}) => {
  const { formatString } = useFormat(pagePrefix);

  return (
    <PageWrapper contentClassName={styles.wrapperContent}>
      <H_3 className={styles.mainHeading} textAlign={e_textAlign.CENTER}>
        <Translate id={pagePrefix(`heading`)} />
      </H_3>
      <p className={styles.subHeading}>
        {getContentValues(
          variant,
          data,
          formatString(`content${getVariantPostFix(variant)}`)
        )}
      </p>
      <ContactForm_Button
        biggerFont={true}
        thickerFont={true}
        onClick={page.close}
      >
        <Translate id={pagePrefix("close")} />
      </ContactForm_Button>
    </PageWrapper>
  );
};
