import React, { useState } from "react";
import * as styles from "./index.module.scss";
import { Link } from "gatsby";
import { i_defaultComponentProps } from "../../utils/interfaces";
import { cls } from "../../utils/helpers";
import { e_fontWeight, Text } from "../typography/text";
import ArrowIcon from "../../assets/icons/arrow.svg";

interface i_dropdownProps extends i_defaultComponentProps {
  label: string;
  open: boolean;
  setOpen: () => void;
}

export const Dropdown: React.FC<i_dropdownProps> = ({
  className,
  children,
  label,
  open,
  setOpen,
}) => {
  return (
    <div
      className={cls(
        styles.dropdown,
        open && styles.active,
        className
      )}
      onClick={() => setOpen()}
    >
      <Text className={styles.label} fontWeight={e_fontWeight.w700}>
        {label}
        <ArrowIcon className={styles.icon} />
      </Text>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export type t_dropdownTextItem = {
  label: string;
  to: string;
};

interface i_dropdownTextProps extends i_defaultComponentProps {
  items: t_dropdownTextItem[];
  label: string;
  light?: boolean;
}

export const DropdownText: React.FC<i_dropdownTextProps> = ({
  className,
  label,
  items,
  light,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      className={cls(className, light && styles.lightVariant)}
      label={label}
      open={dropdownOpen}
      setOpen={() => setDropdownOpen(!dropdownOpen)}
    >
      <ul>
        {items.map((item, index) => {
          return (
            <li
              key={label + "_dropdown_" + index}
              className={styles.dropdownTextItem}
            >
              <Link title={""} to={item.to}>
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </Dropdown>
  );
};
