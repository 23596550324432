import { postData } from "../../../../../../utils/helpers";
import { getEmailEndpoint } from "../../../../../../config";
import { t_clientCategory } from "../../components/contact-field";

export function sendEmail({
  clientType,
  captchaResult,
  contact,
  message,
  selectedLang,
  seller,
  clientCategory,
  compnayNameOrVat,
}: {
  contact: string;
  seller: string;
  message: string;
  clientType: string;
  selectedLang: string;
  captchaResult: string;
  clientCategory: t_clientCategory | undefined;
  compnayNameOrVat: string;
}) {
  if (seller) {
    return postData(getEmailEndpoint(), {
      seller,
      clientType,
      userData: {
        contact,
        selectedLang,
        clientCategory,
        message,
        compnayNameOrVat,
      },
      captchaResult,
    });
  }

  throw new Error("Missing seller contact.");
}
