import React, { useContext, useEffect, useState } from "react";
import * as styles from "./index.module.scss";
import { PageWrapper } from "../../components/page-wrapper";
import { i_contactFormPage } from "../../types";
import { transPrefix } from "../../../../../../utils/helpers";
import { e_contactFormPages, ModalStateContext } from "../../index";
import { useFormat } from "../../../../../../hooks/useFormat";
import { Form_SubHeading } from "../../components/subheading";
import { i_locationWithCountry } from "../../maps";
import { ContactForm_Button } from "../../components/button";
import { Translate } from "../../../../../../components/translate";
import { ContactForm_Loader } from "../../components/loader";

const rootPrefix = transPrefix("contact-form");
const compPrefix = transPrefix("contact-form_pageLoader");

export const ContactForm_LoaderPage: React.FC<i_contactFormPage> = ({
  page,
  data,
}) => {
  const [formData, setFormData] = data;
  const { formatString } = useFormat(compPrefix);
  const { api, error } = useContext(ModalStateContext);
  const [localError, setLocalError] = useState("");

  useEffect(() => {
    const load = async () => {
      if (api && formData.selectedLocation) {
        const userCountry = (() => {
          for (const component of formData.selectedLocation
            .address_components) {
            for (const type of component.types) {
              if (type === "country") return component.short_name;
            }
          }
        })();

        if (userCountry) {
          const userLocation: i_locationWithCountry = {
            ...formData.selectedLocation.geometry.location,
            country: userCountry,
          };

		console.log(userLocation)

          const sellers = await api.getSellersInCountry(
            userLocation,
            formData.allSellers
          );

          if (sellers.length > 0) {
            setFormData({
              ...formData,
              sellersInCountry: sellers,
            });
          } else {
            page.next(e_contactFormPages.NO_SELLER, true);
            // no seller in country found
            return false;
          }
        } else {
          // country not received from google
        }
        return true;
      }
    };

    load()
      .then((cont) => {
        if (cont) {
          setTimeout(() => {
            page.next(e_contactFormPages.FIND_SELLER, true);
          }, 200);
        }
      })
      .catch((e) => {
        console.error(e);
        setLocalError(
          formatString("error_closestSellerLookup", "contact-form")
        );
      });
  }, []);

  return (
    <PageWrapper
      closeHandler={page.close}
      contentClassName={styles.page}
      heading={formatString("heading")}
      subHeading={
        <Form_SubHeading>
          <Translate id={compPrefix("subHeading")} />
        </Form_SubHeading>
      }
      error={localError ? localError : error}
      backButtonHandler={error || localError ? page.back : undefined}
    >
      {!error && !localError ? (
        <ContactForm_Loader />
      ) : (
        <ContactForm_Button
          onClick={() => page.next(e_contactFormPages.ALL_SELLERS)}
          biggerFont
        >
          <Translate id={rootPrefix("showAll")} />
        </ContactForm_Button>
      )}
    </PageWrapper>
  );
};
