// extracted by mini-css-extract-plugin
export var bg = "r_b8";
export var content = "r_f";
export var contentContainer = "r_cP";
export var img = "r_b1";
export var layout = "r_b2";
export var listItem = "r_cR";
export var specItem = "r_cS";
export var subTitle = "r_cQ";
export var title = "r_b3";
export var wrapper = "r_D";