import React, {ReactElement} from "react";
import * as styles from "./index.module.scss";
import {H_3} from "../typography/heading";
import {i_defaultComponentProps} from "../../utils/interfaces";
import {cls} from "../../utils/helpers";
import {e_textTag} from "../typography/text";

export enum e_supportLinkType {
  Link,
  Button,
  Download,
}

interface i_panelProps extends i_defaultComponentProps {
  heading: ReactElement;
  icon: ReactElement;
  type?: e_supportLinkType;
  handler: string | Function;
  title?: string;
}

export const Panel: React.FC<i_panelProps> = ({
  className,
  heading,
  icon,
  children,
  type = e_supportLinkType.Link,
  handler = "",
  title,
}) => {
  if (type === e_supportLinkType.Button && typeof handler !== "function") {
    throw new Error(
      "Error in Panel props! Type button can only have function as a handler."
    );
  }

  return React.createElement(
    type !== e_supportLinkType.Button ? "a" : "div",
    {
      className: cls(styles.panel, className),
      href: handler,
      download: type === e_supportLinkType.Download,
      title: title,
    },
    <>
      <div className={styles.icon}>{icon}</div>
      <div>
        <H_3 tag={e_textTag.p} className={styles.heading}>{heading}</H_3>
        {children}
      </div>
    </>
  );
};

export const PanelWrapper: React.FC = ({ children }) => {
  return <div className={styles.panelWrapper}>{children}</div>;
};
