import React from "react";
import * as styles from "./index.module.scss";
import { e_sellerAction, e_sellerType, i_seller } from "../../types";
import { cls, transPrefix } from "../../../../../../utils/helpers";
import { Button } from "../../../../../../components/button";
import { Translate } from "../../../../../../components/translate";

const compPrefix = transPrefix("contact-form");

interface i_sellerProps {
  data: i_seller;
  active: boolean;
  detailed?: boolean;
  onClick?: () => void;
  onAction: (
    actionNumber: e_sellerAction,
    data: i_seller,
    url?: string
  ) => void;
  className?: string;
  index?: number;
}

export const Seller: React.FC<i_sellerProps> = ({
  data,
  onAction,
  detailed = false,
  className,
  onClick,
}) => {
  const { contact, country, link, name, type, purchaseLink } = data;

  function handleComplete() {
    const action =
      type === e_sellerType.contact
        ? e_sellerAction.contact
        : purchaseLink
        ? e_sellerAction.buy
        : e_sellerAction.store;

    onAction(action, data, purchaseLink ? purchaseLink : link);
  }

  return (
    <div
      className={cls(styles.seller, className, detailed && styles.detailed)}
      onClick={onClick}
    >
      <h1 className={styles.contactName}>{name}</h1>

      {detailed && <span className={styles.distance}>{data.distance} Km</span>}

      <div className={styles.content}>
        <div className={styles.contactInfo}>
          {contact.address.split("||").map((part, index) => (
            <p key={index}>{part}</p>
          ))}
        </div>
        <SellerAction
          handleComplete={handleComplete}
          buttonType={type}
          hasPurchaseLink={!!purchaseLink}
        />
      </div>
    </div>
  );
};

interface i_sellerActionProps {
  buttonType: number;
  handleComplete: () => void;
  hasPurchaseLink: boolean;
}

const SellerAction: React.FC<i_sellerActionProps> = ({
  handleComplete,
  buttonType,
  hasPurchaseLink,
}) => {
  const isBuyLink = buttonType === e_sellerType.store && hasPurchaseLink;

  return (
    <>
      <Button
        className={cls(styles.button, isBuyLink && styles.buy)}
        onClick={(e) => {
          e.stopPropagation();
          handleComplete();
        }}
        uppercase={false}
      >
        {buttonType === e_sellerType.store &&
          (isBuyLink ? (
            <Translate id={compPrefix("addToCart")} />
          ) : (
            <Translate id={compPrefix("visitStore")} />
          ))}
        {buttonType === e_sellerType.contact && (
          <Translate id={compPrefix("contactSeller")} />
        )}
      </Button>
    </>
  );
};
