import React from "react";
import * as styles from "./index.module.scss";
import { Button, i_buttonProps } from "../../../../../../components/button";
import { cls } from "../../../../../../utils/helpers";
import { e_fontWeight } from "../../../../../../components/typography/text";

interface i_contactFormButtonProps extends i_buttonProps {
  loader?: React.ReactElement;
  loaderActive?: boolean;
}

export const ContactForm_Button: React.FC<i_contactFormButtonProps> = ({
  className,
  children,
  submit,
  biggerFont = false,
  thickerFont = false,
  loader,
  loaderActive,
  ...__other
}) => {
  return (
    <Button
      loader={loader}
      loaderActive={loaderActive}
      className={cls(
        styles.button,
        biggerFont && styles.button__biggerFont,
        thickerFont && styles.button__thickerFont,
        className
      )}
      submit={submit}
      uppercase={false}
      fontWeight={thickerFont ? e_fontWeight.w900 : e_fontWeight.w700}
      {...__other}
    >
      {children}
    </Button>
  );
};
