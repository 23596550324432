import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as styles from "./index.module.scss";
import {
  cls,
  iterateLanguages,
  navPrefix,
  setClassIf,
} from "../../../../utils/helpers";
import {
  DropdownText,
  t_dropdownTextItem,
} from "../../../../components/dropdown";
import { Container } from "../../../../components/container";
import { PageStateContext } from "../../../../components/page-state-provider";
import { Social } from "../../../../components/social";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useFormat } from "../../../../hooks/useFormat";
import { Translate } from "../../../../components/translate";
import MooverLogo from "../../../../assets/icons/moover-logo.svg";
import IconLongArrow from "../../../../assets/icons/arrow-long.svg";

export const SECTIONS = {
  TOP: null,
  TRY: 0,
  BENEFITS: 1,
  MODEL: 2,
  INSTALLATION: 3,
  FAQ: 4,
  GALLERY: 5,
  SUPPORT: 6,
};

const mappedLanguages: t_dropdownTextItem[] = (() => {
  let arr: t_dropdownTextItem[] = [];
  iterateLanguages((lang) => {
    arr.push({
      to: `/${lang}`,
      label: lang.toUpperCase(),
    });
  });
  return arr;
})();

interface i_navBarProps {
  navVisible: boolean;
  navLightMode: boolean;
  setCurrentSection: (name: number | null) => void;
}

function activateObserver(
  observer: IntersectionObserver,
  links: (HTMLAnchorElement | null)[]
) {
  for (const link of links) {
    if (!link?.href.includes("#")) continue;
    const targetId = link?.href.split("#")[1];
    const target = document.getElementById(targetId);
    if (target) observer.observe(target);
  }
}

let preventIntersection = false;
let preventIntersectionTimeout: any;

export const Index_NavBar: React.FC<i_navBarProps> = ({
  navVisible,
  navLightMode,
  setCurrentSection,
}) => {
  const { formatString, intl } = useFormat(navPrefix);
  const { isTouchDevice, viewportWidth } = useContext(PageStateContext);
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const underlineRef = useRef<HTMLDivElement>(null);
  const linksRef = useRef<(HTMLAnchorElement | null)[]>([]);

  // move underline
  useEffect(() => {
    // ignore with mobile menu
    if (viewportWidth < 900) return;

    const links = linksRef.current;
    const underline = underlineRef.current;
    const wrapper = wrapperRef.current;

    if (underline && links.length && wrapper && activeLink !== null) {
      const selectedLink = links[activeLink];

	  if(!selectedLink) return

      let { paddingLeft, paddingRight } = getComputedStyle(
        selectedLink
      );

      const pl = parseInt(paddingLeft.split("px")[0]);
      const pr = parseInt(paddingRight.split("px")[0]);

      if (selectedLink) {
        const { width, left } = selectedLink.getBoundingClientRect();
        const wrapperOffset = wrapper.getBoundingClientRect().left;

        underline.style.width = `${width - (pl + pr)}px`;
        underline.style.left = `${left - wrapperOffset + pl}px`;
      }
    }
  }, [activeLink, underlineRef, linksRef, wrapperRef, viewportWidth]);

  function handleNavLink(e: any, linkIndex?: number) {
    preventIntersection = true;
    e.preventDefault();

    if (linkIndex !== undefined) {
      setActiveLink(linkIndex);
    } else {
      setActiveLink(null);
    }

    const linkName = e.target.href.split("#")[1];
    scrollTo("#" + linkName);
    if (mobileMenuExpanded) {
      setMobileMenuExpanded(false);
    }

    let urlHistory = window.location.href.replace(/#.*/, "");
    if (linkName !== "top") {
      urlHistory = `${window.location.href.split("#")[0]}#${
        e.target.href.split("#")[1]
      }`;
    }

    history.pushState({}, "", urlHistory);
    if (preventIntersectionTimeout) {
      clearTimeout(preventIntersectionTimeout);
    }
    preventIntersectionTimeout = setTimeout(() => {
      preventIntersection = false;
    }, 1000);
  }

  useLayoutEffect(() => {
    const links = linksRef.current;
    let observer: IntersectionObserver;

    if (links.length) {
      const callback = (entries: IntersectionObserverEntry[]) => {
        if (preventIntersection) return;

        const entry = entries
          .filter((entry) => entry.isIntersecting)
          .sort((a, b) => b.intersectionRatio - a.intersectionRatio)[0];

        if (entry) {
          const sectionId = entry.target.id;
          const assignedLink = linksRef.current.findIndex(
            (link) => link?.href.split("#")[1] === sectionId
          );

          const url =
            sectionId !== "top"
              ? `${window.location.href.split("#")[0]}#${sectionId}`
              : window.location.href.replace(/#.*/, "");

          history.pushState({}, "", url);
          setCurrentSection(sectionId !== "top" ? assignedLink : null);
          setActiveLink(sectionId !== "top" ? assignedLink : null);
        }
      };

      if (linksRef.current) {
        const anchor = window.location.href.split("#")[1];

        observer = new IntersectionObserver(callback, {
          threshold: isTouchDevice ? [0.1, 0.9] : [0.5, 1],
        });

        if (anchor) {
          const link = linksRef.current.find((link) => {
            if (link) {
              return link.href.includes(anchor);
            } else {
              return false;
            }
          });
          if (link) {
            setTimeout(() => {
              const target = document.getElementById(anchor);
              target?.scrollIntoView();
              activateObserver(observer, links);
            }, 100);
          }
        } else {
          activateObserver(observer, links);
        }
      }
    }

    return function () {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [linksRef]);

  useEffect(() => {
    const el = navRef.current;
    if (el) {
      setClassIf(navVisible, el, styles.active);
      setClassIf(mobileMenuExpanded, el, styles.expanded);
      setClassIf(isTouchDevice, el, styles.isTouch);
    }
  }, [navVisible, mobileMenuExpanded, isTouchDevice, navRef]);

  return (
    <nav className={cls(styles.navBar, styles.animated)} ref={navRef}>
      <Container className={styles.container}>
        <DropdownText
          className={styles.langSelect}
          items={mappedLanguages.filter(
            (lang) => intl.locale !== lang.label.toLowerCase()
          )}
          light={navLightMode}
          label={intl.locale.toUpperCase()}
        />
        <a
          href={"#top"}
          onClick={(e) => handleNavLink(e)}
          className={styles.logoLink}
          ref={(el) => (linksRef.current[0] = el)}
          title={"Moover Logo"}
        >
          <MooverLogo />
        </a>
        <div className={styles.navContent}>
          <div className={styles.navLinks} ref={wrapperRef}>
            {Array.from(new Array(7)).map((item, i) => {
              const translatedLink = `#${formatString(`link${i}Anchor`)}`;
              const translatedTitle = formatString(`link${i}`);
              return (
                <a
                  key={i}
                  title={translatedTitle}
                  href={translatedLink}
                  onClick={(e) => {
                    handleNavLink(e, i + 1);
                  }}
                  ref={(el) => (linksRef.current[i + 1] = el)}
                  className={cls(
                    styles.link,
                    i + 1 === activeLink && styles.active
                  )}
                >
                  {translatedTitle}
                </a>
              );
            })}
          </div>
          <div className={styles.mobileExtras}>
            <a
              href={`https://${formatString("linkAlbixon")}/`}
              className={styles.albixonLink}
            >
              <Translate id={navPrefix("linkAlbixon")} />
              <IconLongArrow />
            </a>
            <Social className={styles.social} />
          </div>
          <div
            className={cls(
              styles.underlineBar,
              activeLink === null && styles.hidden
            )}
            ref={underlineRef}
          />
        </div>
        <div
          className={styles.mobileMenuToggle}
          onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}
        >
          <div />
          <div />
          <div />
        </div>
      </Container>
    </nav>
  );
};
