import React, { useContext, useEffect, useState } from "react";
import * as styles from "./index.module.scss";
import { PageWrapper } from "../../components/page-wrapper";
import {
  e_sellerAction,
  i_contactFormPage,
  i_seller,
  COUNTRY_CODES,
} from "../../types";
import { transPrefix } from "../../../../../../utils/helpers";
import { useFormat } from "../../../../../../hooks/useFormat";
import { Seller } from "../../components/seller";
import { PageStateContext } from "../../../../../../components/page-state-provider";
import { e_contactFormPages } from "../../index";
import { ContactFormSelect } from "../../components/input";
import { ContactForm_Button } from "../../components/button";

function createSellerComponent(
  seller: i_seller,
  onAction: (actionNumber: e_sellerAction, data: i_seller, url?: string) => void
) {
  return (
    <Seller
      className={styles.seller}
      key={seller.id}
      data={seller}
      onAction={onAction}
      active={false}
    />
  );
}

export const ContactForm_AllSellersPage: React.FC<i_contactFormPage> = ({
  page,
  data,
}) => {
  const [formData, setFormData] = data;
  const { formatString } = useFormat(
    transPrefix("contact-form_pageAllSellers")
  );
  const [activeCountry, setActiveCountry] = useState<null | number>(null);
  const { viewportWidth } = useContext(PageStateContext);

  useEffect(() => {
    if (viewportWidth < 700) {
      setActiveCountry(null);
    }
  }, [viewportWidth]);

  function handleAction(
    actionNumber: e_sellerAction,
    seller: i_seller,
    url?: string
  ) {
    if (actionNumber === e_sellerAction.buy) {
      // call store api
      page.close();
    }

    if (actionNumber === e_sellerAction.store) {
      // redirect store
      if (seller.link) {
        window.location.href = seller.link;
      }
    }

    if (actionNumber === e_sellerAction.contact) {
      // redirect to form
      setFormData({
        ...formData,
        contactSeller: seller,
      });
      page.next(e_contactFormPages.CONTACT_SELLER);
    }
  }

  const filteredSellers = (() => {
    const res: i_seller[][] = Object.keys(COUNTRY_CODES).map((country) => []);
    formData.allSellers.forEach((seller) => res[seller.country].push(seller));
    return res;
  })();

  return (
    <PageWrapper
      closeHandler={page.close}
      heading={formatString("heading")}
      backButtonHandler={page.back}
      contentClassName={styles.content}
    >
      <div className={styles.test}>
        <CountrySelect
          countries={Object.keys(COUNTRY_CODES)}
          onSelect={setActiveCountry}
        />

        <ContactForm_Button
          className={styles.test1}
          onClick={() => {
            setFormData({
              ...formData,
            });
            page.next(e_contactFormPages.CONTACT_ALBIXON_B2C);
          }}
        >
          {formatString("contactAlbixon", "contact-form")}
        </ContactForm_Button>
      </div>

      <div className={styles.sellersWrapper}>
        {activeCountry === null ? (
          filteredSellers.map((countrySellers, countryCode) => {
			if(!countrySellers.length) return null;

            return (
              <div key={countryCode}>
                <p className={styles.countryHeading}>
                  {formatString(
                    Object.keys(COUNTRY_CODES)[countryCode],
                    "contact-form"
                  )}
                </p>
                <div className={styles.sellersList}>
                  {countrySellers.map((seller) =>
                    createSellerComponent(seller, handleAction)
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.sellersList}>
            {filteredSellers[activeCountry].map((seller) =>
              createSellerComponent(seller, handleAction)
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

const CountrySelect: React.FC<{
  countries: string[];
  onSelect: (index: number | null) => void;
}> = ({ countries, onSelect }) => {
  const { formatString } = useFormat();
  const options = ["all"].concat(countries);

  return (
    <ContactFormSelect
      id={"country"}
      name={"country"}
      className={styles.countrySelect}
      onChange={(value) => {
        if (value === "all") {
          onSelect(null);
          return;
        }
        onSelect(COUNTRY_CODES[value]);
      }}
      label={formatString("countriesSelectLabel", "contact-form")}
      options={options.map((opt) => {
        return {
          label: formatString(opt, "contact-form"),
          value: opt,
        };
      })}
    />
  );
};
