//@refactored
import React, { useContext, useEffect, useRef } from "react";
import KeenSlider from "../../../../lib/keen-slider/";
import * as styles from "./index.module.scss";
import { cls, navPrefix } from "../../../../utils/helpers";
import { filterImage } from "../../../../components/image";
import { PageStateContext } from "../../../../components/page-state-provider";
import { useFormat } from "../../../../hooks/useFormat";
import Img from "gatsby-image";
import {
  compPrefix,
  e_slideType,
  getNumberOfRows,
  getSlideCountForRow,
  getSlideIndex,
  getStartingSlideForRow,
  i_slide,
  slides,
  useImageQueryData,
} from "./data";
import ArrowIcon from "../../../../assets/icons/arrow.svg";

const keenOptions = {
  mode: "free",
  slidesPerView: 1.5,
  loop: true,
  spacing: 10,
  friction: 0.01,
  breakpoints: {
    "(min-width: 768px)": {
      slidesPerView: 2,
    },
    "(min-width: 1200px)": {
      slidesPerView: 3.5,
    },
  },
};

export const Index_Gallery: React.FC = () => {
  const rows = useRef<any[]>([]);
  const rowRefs = useRef<any[]>(new Array(slides.length));
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { viewportWidth } = useContext(PageStateContext);
  const { formatString } = useFormat(compPrefix);

  useEffect(() => {
    rowRefs.current.forEach((rowRef, index) => {
      if (rowRef) {
		// @ts-ignore
        const slider = new KeenSlider(rowRef, keenOptions);
        const { speed, offset } = ((index: number) => {
          switch (index) {
            case 0:
              return {
                speed: 0.3,
                offset: -0.5,
              };
            case 1:
              return {
                speed: -0.2,
                offset: 0,
              };
            default:
              return {
                speed: 0.15,
                offset: 0.8,
              };
          }
        })(index);

        rows.current.push({
          api: slider,
          speed,
          offset,
        });

        slider.moveToSlide(offset);
        slider.autoMove(speed);
      }
    });

    return function () {
      rows.current.forEach((row) => {
        if (row) {
          row.api.destroy();
        }
      });
    };
  }, [rowRefs]);

  useEffect(() => {
    rows.current.forEach((row) => {
      if (row) {
        row.api.moveToSlide(row.offset);
      }
    });
  }, [viewportWidth]);

  return (
    <div
      ref={wrapperRef}
      id={formatString("link5Anchor", navPrefix)}
      className={cls(
        styles.container,
        getNumberOfRows() === 1 ? null : styles.spacedOut
      )}
    >
      {Array.from(new Array(getNumberOfRows())).map((item, rowIndex) => {
        return (
          <div
            key={rowIndex}
            ref={(el) => (rowRefs.current[rowIndex] = el)}
            className={cls("keen-slider", styles.slider)}
            onPointerEnter={() => {
              rows.current[rowIndex].api.pauseAutoMove();
            }}
            onPointerLeave={() => {
              const row = rows.current[rowIndex];
              row.api.autoMove(row.speed);
            }}
          >
            {slides
              .slice(
                getStartingSlideForRow(rowIndex),
                getStartingSlideForRow(rowIndex) + getSlideCountForRow(rowIndex)
              )
              .map((item, slideIndex) => {
                const slideNumber = getSlideIndex(rowIndex, slideIndex);
                return (
                  <div
                    key={slideIndex}
                    className={cls(
                      `keen-slider__slide number-slide${slideIndex}`
                    )}
                  >
                    <GalleryImage
                      alt={formatString(`img${slides[slideNumber].name}Alt`)}
                      name={formatString(`img${slides[slideNumber].name}Src`)}
                      slide={item}
                      className={styles.img}
                    />
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

interface i_galleryImageProps {
  name: string;
  alt: string;
  className: string;
  slide: i_slide;
}

const GALLERY_MIN_IMG_SIZE = 1000;

const GalleryImage: React.FC<i_galleryImageProps> = ({
  name,
  alt,
  className,
  slide,
}) => {
  const data = useImageQueryData();
  let selectedImage = filterImage(data, name, alt);
  const srcSet = selectedImage.childImageSharp.fluid.srcSet;

  let mobxSrc = "";
  if (slide.external) {
    if (!slide.src) {
      throw new Error("External source has to have src attribute.");
    }

    mobxSrc = slide.src;
  } else {
    const splitSets = srcSet.split(",");

    let idealSets = splitSets.filter(
      (imgSrcSet: string) =>
        parseInt(imgSrcSet.split(" ")[1]) >= GALLERY_MIN_IMG_SIZE
    );

    // the source img was not big enough
    if (idealSets.length === 0) {
      idealSets = splitSets;
    }

    // append smallest as default img
    mobxSrc += `${idealSets[0].split(" ")[0]},`;

    idealSets.forEach((imgSrcSet: string, index: number) => {
      mobxSrc += `${imgSrcSet}${index !== idealSets.length - 1 ? "," : ""}`;
    });
  }

  return (
    <div
      className={cls(styles.content, "mobx")}
      data-src={mobxSrc}
      data-thumb={srcSet.split("w,")[0].split(" ")[0]}
      data-rel="gallery"
      data-alt={alt}
    >
      {slide.type === e_slideType.video && (
        <ArrowIcon className={styles.videoIcon} />
      )}
      <Img
        className={className}
        fluid={selectedImage.childImageSharp.fluid}
        alt={alt}
      />
    </div>
  );
};
