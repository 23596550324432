// extracted by mini-css-extract-plugin
export var action = "m_bS";
export var actionIcon = "m_bT";
export var circleFilled = "m_bW";
export var circleOutline = "m_bX";
export var container = "m_g";
export var content = "m_f";
export var h1 = "m_bR";
export var handleArrow = "m_X";
export var text = "m_bV";
export var wrapper = "m_D";