import React from "react";
import { cls } from "./helpers";

export function makeCircle(
  r: number,
  strokeWidth: number = 0,
  className?: string
) {
  const svgSize = r * 2;
  const circleSize = r - strokeWidth / 2;

  return (
    <svg
      width={svgSize}
      height={svgSize}
      viewBox={`0 0 ${svgSize} ${svgSize}`}
      xmlns="http://www.w3.org/2000/svg"
      className={cls(className)}
    >
      <circle
        cx={r}
        cy={r}
        r={circleSize}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
