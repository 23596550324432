// extracted by mini-css-extract-plugin
export var benefit = "p_b9";
export var benefitText = "p_cb";
export var bg = "p_b8";
export var circle = "p_cc";
export var circleFilled = "p_bW";
export var circleOutlined = "p_cd";
export var container = "p_g";
export var content = "p_f";
export var h1 = "p_bR";
export var h4 = "p_b5";
export var img = "p_b1";
export var layout = "p_b2";
export var note = "p_cf";
export var previewBtn = "p_ch";
export var previewBtnWrapper = "p_cg";
export var section = "p_b7";
export var text = "p_bV";
export var text1 = "p_b4";
export var title = "p_b3";
export var white = "p_b6";