// extracted by mini-css-extract-plugin
export var active = "q_6";
export var activeLed = "q_cN";
export var btnLoading = "q_cC";
export var closeBtn = "q_cv";
export var closeDetail = "q_cH";
export var closePoi = "q_cJ";
export var container = "q_g";
export var description = "q_cw";
export var descriptionContainer = "q_cD";
export var descriptionTouch = "q_cF";
export var hotspotBtn = "q_cB";
export var led = "q_cM";
export var leds = "q_cL";
export var mobileWrapper = "q_cy";
export var modal = "q_cm";
export var modelImgPlaceholder = "q_ct";
export var name = "q_cK";
export var noOverFlow = "q_ck";
export var poiDescription = "q_cG";
export var rootWrapper = "q_cj";
export var rotate = "q_cq";
export var rotatePrompt = "q_cn";
export var rotatePromptContent = "q_cr";
export var rotatePromptVisible = "q_cp";
export var rotated = "q_cs";
export var startBtn = "q_cz";
export var touch = "q_cl";
export var viewerWrapper = "q_cx";