// extracted by mini-css-extract-plugin
export var active = "h_6";
export var albixonLink = "h_9";
export var animated = "h_bc";
export var container = "h_g";
export var expanded = "h_bf";
export var hidden = "h_J";
export var isTouch = "h_bd";
export var langSelect = "h_7";
export var link = "h_Z";
export var logoLink = "h_1";
export var mobileExtras = "h_8";
export var mobileMenuToggle = "h_4";
export var navBar = "h_0";
export var navContent = "h_2";
export var navLinks = "h_5";
export var social = "h_bb";
export var underlineBar = "h_3";